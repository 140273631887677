<template>
  <div class="box">
    <h2>{{ benefit.text }}</h2>
    <img src="https://fakeimg.pl/60x60" alt="" />
    <!-- <img
      :src="
        require(`@/assets/images/gold-member/benefits-icons/${benefit.icon}`)
      "
      alt=""
    /> -->
  </div>
</template>

<script>
export default {
  name: "Box",
  props: ["benefit"],
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 241.5px;
  @include media-breakpoint-down(sm) {
    height: 190px;
    width: 80%;
    margin: auto;
  }
  padding: 10px;
  border: 4px solid transparent;
  background: theme-var($--ch-theme-secondary);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: theme-var($--ch-theme-on-secondary);
    padding-bottom: 20px;
    text-transform: uppercase;
  }
}
</style>