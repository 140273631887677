export const tabs = [
  {
    title: "Scrimba",
    mobileTitle: "Scrimba",
    role: "Code Reviewer @ Scrimba",
    time: "2023 - Present",
    desc: `<li>Reviewed students’ front end code submissions weekly for functionality, readability, and adherence to best practices</li>
    <li>Provided constructive feedback and suggestions for improvement to students via comments, annotations, and screen recordings</li>
    <li>Identified and flagged any errors, bugs, or accessibility issues in the code</li>
    <li>Collaborated with instructors, mentors, or peers to ensure consistent and high-quality code reviews</li>
    <li>Stayed updated on the latest trends, tools, and standards in front end development</li>
    `,
  },
  {
    title: "Channel 1",
    mobileTitle: "Channel 1 Media Solutions",
    role: "Frontend Developer @ Channel 1 Media Solutions",
    time: "2021 - 2022",
    desc: `<li>Developed user-friendly, responsive, and sophisticated user-interfaces for major sports teams (NFL, NBA, MLB, NHL etc.)</li>
    <li>Worked with PHP backend and SQL databases to make custom websites specific to clients sent by custom URL</li>
    <li>Independently and collaboratively built websites from scratch using Vue and Git for modularity and continuous integration</li>
    <li>Communicated with clients to meet their requirements and tight deadlines while maintaining positive relations</li>
    <li>Managed and updated older websites and legacy code</li>
    `,
  },
  {
    title: "FTEC",
    mobileTitle: "Fight to End Cancer",
    role: "Web Developer @ Fight to End Cancer",
    time: "2021 - 2021",
    desc: `<li>Created an e-commerce website using the Shopify platform and Liquid.js, JavaScript, HTML, and CSS technologies</li>
    <li>Responsible for the layout, design, and coding of the entire website to enhance user experience and function </li>
    `,
  },
  {
    title: "DRUHC",
    mobileTitle: "Durham Region Unemployed Help Centre",
    role: "Database Designer @ Durham Region Unemployed Help Centre",
    time: "2020 - 2021",
    desc: `<li>Independently designed and developed a custom Client Database based on the employer’s needs to consolidate records, improve reporting, collect more data, and analyze said data</li>
    <li>Collected and documented user’s requirements</li>
    <li>Updated existing documents to follow best practices to guarantee sustainability and scalability by implementing data policies, standards, and models</li>
    <li>Handled confidential/sensitive information securely to meet industry standards</li>
    `,
  },
  {
    title: "Dapper",
    mobileTitle: "Dapper BLVD",
    role: "Co-Founder @ Dapper BLVD",
    time: "2015 - 2019",
    desc: `<li>Co-founded a men’s accessory e-commerce boutique with over 1000 sales and hosted over 10 boutique brands</li>
    <li>Designed and managed website using the Shopify platform, including web design, transactions, customer management and more</li>
    <li>Delegated tasks to staff including ordering products in bulk, innovating designs, assembling merchandise</li>
    <li>Demonstrated effective written and oral communication with partners and clients, leading to exposure through fashions shows and influencer content and increased sales</li>
    `,
  },
  {
    title: "Robarts",
    mobileTitle: "Robarts Research Institute",
    role: "Software Engineer Intern @ Robarts Research Institute",
    time: "2017 - 2018",
    desc: `<li>Used CPP to create a Slicer module capable of replicating X-ray results, used by nurses to practice virtual spinal interventions</li>
    <li>Created in-depth documentation for medical practitioners and future developers to use in addition to comprehensive commenting of code, leading to future project advances</li>
    `,
  },
];
