<template>
  <svg
    id="eqAba0CoI3y1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1280 1280"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <defs>
      <linearGradient
        id="eqAba0CoI3y2-fill"
        x1="617.44"
        y1="457.05"
        x2="1002.28"
        y2="72.22"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y2-fill-0" offset="0%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y2-fill-1" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y3-fill"
        x1="579.39"
        y1="418.86"
        x2="872.02"
        y2="126.23"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y3-fill-0" offset="0%" stop-color="#f1d55e" />
        <stop id="eqAba0CoI3y3-fill-1" offset="100%" stop-color="#6c5909" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y4-fill"
        x1="123.68"
        y1="935.99"
        x2="508.3"
        y2="551.37"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y4-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y4-fill-1" offset="42%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y4-fill-2" offset="71%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y4-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y5-fill"
        x1="124.26"
        y1="862.21"
        x2="467.29"
        y2="519.18"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y5-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y5-fill-1" offset="52%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y5-fill-2" offset="73%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y5-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y6-fill"
        x1="124.26"
        y1="787.86"
        x2="467.29"
        y2="444.83"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y6-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y6-fill-1" offset="44%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y6-fill-2" offset="72%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y6-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y7-fill"
        x1="744.83"
        y1="835.66"
        x2="945.68"
        y2="634.81"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y7-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y7-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y7-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y7-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y8-fill"
        x1="124.26"
        y1="713.5"
        x2="467.29"
        y2="370.47"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y8-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y8-fill-1" offset="40%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y8-fill-2" offset="73%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y8-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y9-fill"
        x1="487.62"
        y1="795.03"
        x2="728.46"
        y2="554.18"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y9-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y9-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y9-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y9-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y10-fill"
        x1="771.86"
        y1="883.13"
        x2="988.18"
        y2="666.81"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y10-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y10-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y10-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y10-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y11-fill"
        x1="123.72"
        y1="1010.39"
        x2="582.72"
        y2="551.4"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y11-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y11-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y11-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y11-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y12-fill"
        x1="123.76"
        y1="1084.78"
        x2="657.6"
        y2="550.94"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y12-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y12-fill-1" offset="40%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y12-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y12-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y13-fill"
        x1="122.83"
        y1="117.24"
        x2="199.72"
        y2="40.35"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y13-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y13-fill-1" offset="38%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y13-fill-2" offset="71%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y13-fill-3" offset="86%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y14-fill"
        x1="124.26"
        y1="564.8"
        x2="467.29"
        y2="221.77"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y14-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y14-fill-1" offset="40%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y14-fill-2" offset="56%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y14-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y15-fill"
        x1="123.31"
        y1="192.08"
        x2="274.73"
        y2="40.66"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y15-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y15-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y15-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y15-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y16-fill"
        x1="398.14"
        y1="1259.34"
        x2="467.86"
        y2="1189.61"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y16-fill-0" offset="20%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y16-fill-1" offset="60%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y16-fill-2" offset="84%" stop-color="#b99910" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y17-fill"
        x1="123.61"
        y1="341.08"
        x2="423.85"
        y2="40.85"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y17-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y17-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y17-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y17-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y18-fill"
        x1="124.26"
        y1="490.44"
        x2="467.29"
        y2="147.41"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y18-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y18-fill-1" offset="40%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y18-fill-2" offset="56%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y18-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y19-fill"
        x1="124.26"
        y1="416.09"
        x2="467.29"
        y2="73.06"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y19-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y19-fill-1" offset="40%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y19-fill-2" offset="56%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y19-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y20-fill"
        x1="123.51"
        y1="266.63"
        x2="349.35"
        y2="40.78"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y20-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y20-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y20-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y20-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y21-fill"
        x1="843.35"
        y1="1258.43"
        x2="1129.59"
        y2="972.19"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y21-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y21-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y21-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y21-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y22-fill"
        x1="1067.17"
        y1="1259.18"
        x2="1134.88"
        y2="1191.47"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y22-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y22-fill-1" offset="48%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y22-fill-2" offset="78%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y22-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y23-fill"
        x1="992.41"
        y1="1258.78"
        x2="1129.92"
        y2="1121.28"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y23-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y23-fill-1" offset="48%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y23-fill-2" offset="78%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y23-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y24-fill"
        x1="560.75"
        y1="796.38"
        x2="791.31"
        y2="565.81"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y24-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y24-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y24-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y24-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y25-fill"
        x1="124.26"
        y1="1233.99"
        x2="467.29"
        y2="890.96"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y25-fill-0" offset="20%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y25-fill-1" offset="60%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y25-fill-2" offset="84%" stop-color="#b99910" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y26-fill"
        x1="174.22"
        y1="1258.48"
        x2="467.32"
        y2="965.38"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y26-fill-0" offset="20%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y26-fill-1" offset="60%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y26-fill-2" offset="84%" stop-color="#b99910" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y27-fill"
        x1="633.74"
        y1="797.84"
        x2="847.73"
        y2="583.86"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y27-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y27-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y27-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y27-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y28-fill"
        x1="248.68"
        y1="1258.59"
        x2="467.38"
        y2="1039.89"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y28-fill-0" offset="20%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y28-fill-1" offset="60%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y28-fill-2" offset="84%" stop-color="#b99910" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y29-fill"
        x1="697.13"
        y1="808.92"
        x2="898.72"
        y2="607.33"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y29-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y29-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y29-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y29-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y30-fill"
        x1="323.24"
        y1="1258.8"
        x2="467.51"
        y2="1114.53"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y30-fill-0" offset="20%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y30-fill-1" offset="60%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y30-fill-2" offset="84%" stop-color="#b99910" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y31-fill"
        x1="798.3"
        y1="1228.93"
        x2="1121.93"
        y2="905.29"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y31-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y31-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y31-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y31-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y32-fill"
        x1="1126.01"
        y1="913"
        x2="1126.24"
        y2="912.77"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y32-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y32-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y32-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y32-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y33-fill"
        x1="1110.82"
        y1="851.05"
        x2="1111.02"
        y2="850.85"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y33-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y33-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y33-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y33-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y34-fill"
        x1="1083.91"
        y1="848.72"
        x2="1087.08"
        y2="845.55"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y34-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y34-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y34-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y34-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y35-fill"
        x1="1104.07"
        y1="868.89"
        x2="1107.13"
        y2="865.83"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y35-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y35-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y35-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y35-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y36-fill"
        x1="787.56"
        y1="1165.22"
        x2="1106.41"
        y2="846.37"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y36-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y36-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y36-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y36-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y37-fill"
        x1="1026.45"
        y1="704.4"
        x2="1026.48"
        y2="704.38"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y37-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y37-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y37-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y37-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y38-fill"
        x1="1023.31"
        y1="726.3"
        x2="1023.87"
        y2="725.74"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y38-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y38-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y38-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y38-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y39-fill"
        x1="778.85"
        y1="950.76"
        x2="1025.87"
        y2="703.74"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y39-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y39-fill-1" offset="12%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y39-fill-2" offset="32%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y39-fill-3" offset="77%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y40-fill"
        x1="780.97"
        y1="1023"
        x2="1059.36"
        y2="744.61"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y40-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y40-fill-1" offset="17%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y40-fill-2" offset="39%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y40-fill-3" offset="78%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y41-fill"
        x1="782.68"
        y1="1095.69"
        x2="1084.56"
        y2="793.82"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y41-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y41-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y41-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y41-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y42-fill"
        x1="917.86"
        y1="1258.59"
        x2="1134.79"
        y2="1041.66"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y42-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y42-fill-1" offset="48%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y42-fill-2" offset="78%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y42-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y43-fill"
        x1="124.26"
        y1="1159.63"
        x2="474.38"
        y2="809.51"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y43-fill-0" offset="22%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y43-fill-1" offset="54%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y43-fill-2" offset="85%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y43-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
      <linearGradient
        id="eqAba0CoI3y44-fill"
        x1="124.25"
        y1="639.14"
        x2="466.38"
        y2="297.02"
        spreadMethod="pad"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 0)"
      >
        <stop id="eqAba0CoI3y44-fill-0" offset="0%" stop-color="#4d4007" />
        <stop id="eqAba0CoI3y44-fill-1" offset="33%" stop-color="#6c5909" />
        <stop id="eqAba0CoI3y44-fill-2" offset="66%" stop-color="#b99910" />
        <stop id="eqAba0CoI3y44-fill-3" offset="100%" stop-color="#f1d55e" />
      </linearGradient>
    </defs>
    <path
      id="eqAba0CoI3y2"
      d="M1156.05,359.5c0,168.38-98.47,254.51-183,289.21l-26.61-15.49L918.29,615l-33.42-16.72-34.19-16.71L815,564.09L786.87,552.7l-16.7-5.44-27.33-10.44-24.11-5.63-23.36-2.51-7.06-.76c81.13-12.7,112-58.74,112-127.94c0-81.49-51.48-129.11-160.69-129.11h-167v-249h257.38c287.09,0,426.05,153.69,426.05,337.63Z"
      transform="matrix(0 0 0 0 472.62 21.87)"
      fill="url(#eqAba0CoI3y2-fill)"
    />
    <path
      id="eqAba0CoI3y3"
      d="M982.37,324.19c0,127.55-67.42,204.49-252.49,204.49h-34.37l-7.06-.76c81.13-12.7,112-58.74,112-127.94c0-81.49-51.48-129.11-160.68-129.11h-166.94v-147.45h259.65c169.82,0,249.89,74.05,249.89,200.77Z"
      transform="matrix(0 0 0 0 472.83 123.42)"
      fill="url(#eqAba0CoI3y3-fill)"
    />
    <path
      id="eqAba0CoI3y4"
      d="M124,936.26L508,571.88c4.24974-4.02699,5.615497-10.237701,3.447214-15.676037s-7.432563-9.005628-13.287214-9.003963v0c-3.932207.000211-7.690563,1.620753-10.39,4.48L124,936.26Z"
      transform="matrix(0 0 0 0 124 936.26)"
      fill="url(#eqAba0CoI3y4-fill)"
    />
    <path
      id="eqAba0CoI3y5"
      d="M124,861.91L467,539.65c2.862849-2.689457,4.48763-6.442013,4.49-10.37v0c.000103-5.835647-3.56031-11.080711-8.983877-13.234705s-11.612415-.780892-15.616123,3.464705L124,861.91Z"
      transform="matrix(0 0 0 0 124 861.91)"
      fill="url(#eqAba0CoI3y5-fill)"
    />
    <path
      id="eqAba0CoI3y6"
      d="M124,787.56L467,465.3c2.863388-2.693237,4.488004-6.449031,4.49-10.38v0c.000103-5.835647-3.56031-11.080711-8.983877-13.234705s-11.612415-.780892-15.616123,3.464705L124,787.56Z"
      transform="matrix(0 0 0 0 124 787.56)"
      fill="url(#eqAba0CoI3y6-fill)"
    />
    <path
      id="eqAba0CoI3y7"
      d="M943.55,633.11l-.25-.16c-5.761428-3.742104-13.386849-2.7508-18,2.34L745.13,835.48v0c-.035136.06814-.082606.129172-.14.18-.004298.039885-.004298.080115,0,.12.049258-.042268.102896-.079144.16-.11.031688-.03843.068733-.072107.11-.1v0l200-180.41c3.200388-2.908213,4.877209-7.132183,4.542853-11.443609s-2.642357-8.226369-6.252853-10.606391Z"
      transform="matrix(0 0 0 0 744.986816 835.78)"
      fill="url(#eqAba0CoI3y7-fill)"
    />
    <path
      id="eqAba0CoI3y8"
      d="M124,713.2L467,390.94c2.862849-2.689457,4.48763-6.442013,4.49-10.37v0c.000103-5.835647-3.56031-11.080711-8.983877-13.234705s-11.612415-.780892-15.616123,3.464705L124,713.2Z"
      transform="matrix(0 0 0 0 124 713.2)"
      fill="url(#eqAba0CoI3y8-fill)"
    />
    <path
      id="eqAba0CoI3y9"
      d="M487.94,795.35L728.12,574.44c3.981997-3.708552,5.454387-9.388472,3.775508-14.564477s-6.204846-8.91031-11.605508-9.575523h-.35c-4.458578-.543974-8.905443,1.098461-11.94,4.41L487.94,795.35"
      transform="matrix(0 0 0 0 487.94 792.362551)"
      fill="url(#eqAba0CoI3y9-fill)"
    />
    <path
      id="eqAba0CoI3y10"
      d="M987.24,666c-5.763771-4.922622-14.398411-4.364118-19.48,1.26L772.35,882.63v0c-.020553.049164-.051217.093456-.09.13v0c-.104646.149993-.218183.293584-.34.43l.18-.15-.15.11.14-.14.15-.11h.06l.1-.1L987.8,687.2c2.991673-2.706986,4.67007-6.573028,4.604943-10.607088s-1.867447-7.843909-4.944943-10.452912Z"
      transform="matrix(0 0 0 0 771.92 882.951362)"
      fill="url(#eqAba0CoI3y10-fill)"
    />
    <path
      id="eqAba0CoI3y11"
      d="M124,1010.62L582.47,572c4.255989-4.069891,5.602755-10.322206,3.399782-15.783375s-7.511068-9.02899-13.399782-9.006625v0c-3.933745.001301-7.69547,1.612953-10.41,4.46L124,1010.62Z"
      transform="matrix(0 0 0 0 124 1010.61)"
      fill="url(#eqAba0CoI3y11-fill)"
    />
    <path
      id="eqAba0CoI3y12"
      d="M124,1085L657.47,571.51c9.43-9.07,2.77-25-10.31-24.66h-.36c-3.783098.090763-7.37721,1.672173-10,4.4L124,1085Z"
      transform="matrix(0 0 0 0 124 1085)"
      fill="url(#eqAba0CoI3y12-fill)"
    />
    <path
      id="eqAba0CoI3y13"
      d="M124,118.36L198.63,59c9.25-7.36,4.05-22.25-7.76-22.25v0c-3.93521-.003154-7.641397,1.84994-10,5L124,118.36Z"
      transform="matrix(0 0 0 0 124 118.583775)"
      fill="url(#eqAba0CoI3y13-fill)"
    />
    <path
      id="eqAba0CoI3y14"
      d="M124,564.49L467,242.23c2.862849-2.689457,4.48763-6.442013,4.49-10.37v0c.000103-5.835647-3.56031-11.080711-8.983877-13.234705s-11.612415-.780892-15.616123,3.464705L124,564.49Z"
      transform="matrix(0 0 0 0 124 564.49)"
      fill="url(#eqAba0CoI3y14-fill)"
    />
    <path
      id="eqAba0CoI3y15"
      d="M265.13,36.72v0c-3.927643.001213-7.660921,1.709105-10.23,4.68L124,192.72v0L274.08,60.41c9.36-8.25,3.52-23.69-8.95-23.69Z"
      transform="matrix(0 0 0 0 124 192.72)"
      fill="url(#eqAba0CoI3y15-fill)"
    />
    <path
      id="eqAba0CoI3y16"
      d="M471.44,1198.33v0c0-11.69-14.78-16.77-22-7.56l-52.54,67.36v0l69.39-49.83c3.222234-2.299911,5.139287-6.011179,5.15-9.97Z"
      transform="matrix(0 0 0 0 397.005 1258.13)"
      fill="url(#eqAba0CoI3y16-fill)"
    />
    <path
      id="eqAba0CoI3y17"
      d="M124,341.43L423.49,61.22c4.245576-3.972196,5.631599-10.13594,3.495164-15.54324s-7.361108-8.959145-13.175164-8.95676v0c-3.928517.00553-7.679203,1.63831-10.36,4.51L124,341.43Z"
      transform="matrix(0 0 0 0 124 341.458039)"
      fill="url(#eqAba0CoI3y17-fill)"
    />
    <path
      id="eqAba0CoI3y18"
      d="M124,490.14L467,167.88c2.863388-2.693237,4.488004-6.449031,4.49-10.38v0c-.003993-5.833823-3.565893-11.075136-8.988522-13.226553s-11.609022-.777719-15.611478,3.466553L124,490.14Z"
      transform="matrix(0 0 0 0 124 490.14)"
      fill="url(#eqAba0CoI3y18-fill)"
    />
    <path
      id="eqAba0CoI3y19"
      d="M124,415.78L467,93.52c2.862849-2.689457,4.48763-6.442013,4.49-10.37v0c.000103-5.835647-3.56031-11.080711-8.983877-13.234705s-11.612415-.780892-15.616123,3.464705L124,415.78Z"
      transform="matrix(0 0 0 0 124 415.78)"
      fill="url(#eqAba0CoI3y19-fill)"
    />
    <path
      id="eqAba0CoI3y20"
      d="M124,267.07L348.89,60.94c4.240767-3.883997,5.661288-9.973505,3.576787-15.333019s-7.246175-8.888979-12.996787-8.886981v0c-3.930436.000332-7.678093,1.659905-10.32,4.57L124,267.07Z"
      transform="matrix(0 0 0 0 124 267.07)"
      fill="url(#eqAba0CoI3y20-fill)"
    />
    <path
      id="eqAba0CoI3y21"
      d="M843.05,1258.13L1127.93,994c2.541459-2.340437,3.99124-5.635064,4-9.09v-5.3c-.033001-5.077661-3.160853-9.621203-7.89228-11.464386s-10.108557-.61286-13.56772,3.104386l-267.38,286.9Z"
      transform="matrix(0 0 0 0 842.933421 1258.15)"
      fill="url(#eqAba0CoI3y21-fill)"
    />
    <path
      id="eqAba0CoI3y22"
      d="M1066.12,1258.13l67.13-47.8c3.573047-2.532992,5.506283-6.792989,5.06-11.15v-.37c-.495499-4.9776-3.979291-9.14839-8.789111-10.522309s-9.970987.327448-13.020889,4.292309l-50.34,65.55Z"
      transform="matrix(0 0 0 0 1066.12 1258.13)"
      fill="url(#eqAba0CoI3y22-fill)"
    />
    <path
      id="eqAba0CoI3y23"
      d="M991.76,1258.13l137.27-117c3.108227-2.657539,4.825784-6.593913,4.66-10.68v-.4c-.218144-5.448021-3.739358-10.211072-8.883952-12.017082s-10.870381-.289121-14.446048,3.827082l-118.6,136.27Z"
      transform="matrix(0 0 0 0 992.406616 1258.13)"
      fill="url(#eqAba0CoI3y23-fill)"
    />
    <path
      id="eqAba0CoI3y24"
      d="M784.6,562.17c-4.997797-1.252191-10.279485.341919-13.75,4.15L561,796.63v0l230-210.5c3.786319-3.474151,5.365059-8.743384,4.112856-13.72715s-5.134072-8.880987-10.112856-10.15285Z"
      transform="matrix(0 0 0 0 561 796.63)"
      fill="url(#eqAba0CoI3y24-fill)"
    />
    <path
      id="eqAba0CoI3y25"
      d="M124,1233.68L467,911.42c2.862849-2.689457,4.48763-6.442013,4.49-10.37v0c.000103-5.835647-3.56031-11.080711-8.983877-13.234705s-11.612415-.780892-15.616123,3.464705L124,1233.68Z"
      transform="matrix(0 0 0 0 124 1233.68)"
      fill="url(#eqAba0CoI3y25-fill)"
    />
    <path
      id="eqAba0CoI3y26"
      d="M173.86,1258.13L466.92,985.76c2.880406-2.675498,4.517908-6.428712,4.52-10.36v0c-.015378-5.791158-3.560494-10.987535-8.94699-13.114364s-11.525378-.754124-15.49301,3.464364L173.9,1258.13Z"
      transform="matrix(0 0 0 0 173.86 1258.13)"
      fill="url(#eqAba0CoI3y26-fill)"
    />
    <path
      id="eqAba0CoI3y27"
      d="M843,580.82l-.3-.11c-5.394554-2.079958-11.512804-.629854-15.4,3.65L634.15,797.8l-.09.12v0L634,798v0v0h.07v0h.05c0,0,0,0,0,0l.09-.09v0L847.33,604.18c3.565039-3.261332,5.196413-8.130456,4.315802-12.881279s-4.148724-8.71178-8.645802-10.478721Z"
      transform="matrix(0 0 0 0 634 798)"
      fill="url(#eqAba0CoI3y27-fill)"
    />
    <path
      id="eqAba0CoI3y28"
      d="M248.22,1258.13l218.64-198.07c2.916404-2.634029,4.58055-6.380175,4.58-10.31v0c-.002665-5.741583-3.530304-10.892458-8.882773-12.970163s-11.431468-.655905-15.307227,3.580163l-199,217.77Z"
      transform="matrix(0 0 0 0 248.22 1258.14)"
      fill="url(#eqAba0CoI3y28-fill)"
    />
    <path
      id="eqAba0CoI3y29"
      d="M697.58,808.84v0v0v0v0v0L898.3,627.65c3.342914-3.062402,4.995963-7.553977,4.435842-12.052827s-3.264065-8.447964-7.255842-10.597173l-.27-.15c-5.650466-3.000062-12.618307-1.762435-16.89,3L697.53,808.75v0c0,0,0,0,0,.06l-.22.26.23-.17Z"
      transform="matrix(0 0 0 0 697.31 809.07)"
      fill="url(#eqAba0CoI3y29-fill)"
    />
    <path
      id="eqAba0CoI3y30"
      d="M322.57,1258.13L466.74,1134.3c2.981141-2.558971,4.697525-6.291192,4.7-10.22v0c0-12.44-15.42-18.23-23.61-8.88L322.57,1258.13Z"
      transform="matrix(0 0 0 0 322.65 1258.13)"
      fill="url(#eqAba0CoI3y30-fill)"
    />
    <path
      id="eqAba0CoI3y31"
      d="M1125.85,913.28l-.06-.34c-.876826-5.31993-4.694845-9.682306-9.851687-11.256299s-10.760158-.087236-14.458313,3.836299l-3,3.19v0l-300.42,320v0l320.71-300.24v0l2.75-2.57c3.462016-3.218475,5.086811-7.954019,4.33-12.62Z"
      transform="matrix(0 0 0 0 798.06 1228.71)"
      fill="url(#eqAba0CoI3y31-fill)"
    />
    <path
      d="M1126.15,913c0-.11,0-.23-.05-.34v-.06l.09.55c0,0-.03-.09-.04-.15Z"
      opacity="0.44"
      fill="url(#eqAba0CoI3y32-fill)"
    />
    <path
      d="M1111,851.14l-.1-.31-.07-.18c.07.2.14.4.2.6Z"
      opacity="0.36"
      fill="url(#eqAba0CoI3y33-fill)"
    />
    <polygon
      points="1087.03,845.5 1083.96,848.77 1083.96,848.77 1087.03,845.5 1087.03,845.5"
      opacity="0.36"
      fill="url(#eqAba0CoI3y34-fill)"
    />
    <polygon
      points="1104.02,868.84 1104.02,868.84 1107.18,865.88 1107.18,865.88 1104.02,868.84"
      opacity="0.36"
      fill="url(#eqAba0CoI3y35-fill)"
    />
    <path
      id="eqAba0CoI3y36"
      d="M1109.87,852.25c-.027311-.10518-.060701-.208687-.1-.31-1.551261-4.775499-5.509188-8.376722-10.409544-9.471395s-10.014502.479972-13.450456,4.141395l-3.07,3.27c-.29.31-.54.62-.8.94L787.41,1165.07l314.75-294.49c.24-.21.49-.4.74-.63l3.16-3c4.014457-3.745803,5.499579-9.475804,3.81-14.7Z"
      transform="matrix(0 0 0 0 787.41 1165.07)"
      fill="url(#eqAba0CoI3y36-fill)"
    />
    <path
      d="M1026.54,704.46l-.2-.22-.1-.1.44.5Z"
      fill="url(#eqAba0CoI3y37-fill)"
    />
    <polygon
      points="1023.88,725.75 1023.3,726.29 1023.3,726.29 1023.88,725.75"
      fill="url(#eqAba0CoI3y38-fill)"
    />
    <path
      id="eqAba0CoI3y39"
      d="M1025.6,724.14c5.607124-5.217,6.020166-13.957488.93-19.68l-.2-.22c-2.661781-2.995701-6.472533-4.717258-10.479902-4.734432s-7.832738,1.671656-10.520098,4.644432l-2.13,2.35v0l-222.61,246v0L1023.3,726.29v0Z"
      transform="matrix(0 0 0 0 780.59 952.5)"
      fill="url(#eqAba0CoI3y39-fill)"
    />
    <path
      id="eqAba0CoI3y40"
      d="M1060.09,745.4l-.2-.22c-5.013077-5.632615-13.572076-6.320155-19.42-1.56-.953352.63217-1.829328,1.373896-2.61,2.21l-257,277.07v0l276-255.7v0l2.31-2.15c5.584884-5.216614,5.993045-13.934389.92-19.65Z"
      transform="matrix(0 0 0 0 780.86 1024.172725)"
      fill="url(#eqAba0CoI3y40-fill)"
    />
    <path
      id="eqAba0CoI3y41"
      d="M1087.23,797.69l-.14-.28c-2.079267-4.157781-6.063215-7.033438-10.66424-7.697565s-9.23557.967509-12.40576,4.367565l-2.94,3.16c-.24.25-.44.51-.66.77L782.56,1095.56L1079.8,818.42c.462358-.35014.906285-.723973,1.33-1.12l3.08-2.88c4.596334-4.294801,5.824627-11.099222,3.02-16.73Z"
      transform="matrix(0 0 0 0 782.56 1095.56)"
      fill="url(#eqAba0CoI3y41-fill)"
    />
    <path
      id="eqAba0CoI3y42"
      d="M1138.7,1049.64l-.06-.34c-.879868-5.318227-4.699085-9.67764-9.855386-11.249347s-10.757839-.08386-14.454614,3.839347l-3,3.19.06.06-194,213L1131,1065.42c.098685-.113233.188928-.233557.27-.36v.06l3.05-2.85c3.48508-3.20946,5.129738-7.951933,4.38-12.63Z"
      transform="matrix(0 0 0 0 917.39 1258.14)"
      fill="url(#eqAba0CoI3y42-fill)"
    />
    <path
      id="eqAba0CoI3y43"
      d="M478.4,817.71v-.35c-.899217-5.473274-4.828109-9.961239-10.134367-11.576463s-11.069367-.077506-14.865633,3.966463L450.3,813v0L124,1159.33L470.75,833.5l.11.1l3.12-2.92c3.553575-3.309565,5.213066-8.179158,4.42-12.97Z"
      transform="matrix(0 0 0 0 124 1159.33)"
      fill="url(#eqAba0CoI3y43-fill)"
    />
    <path
      id="eqAba0CoI3y44"
      d="M467,297.74l-.2-.22c-2.661781-2.995701-6.472533-4.717258-10.479902-4.734432s-7.832738,1.671656-10.520098,4.644432l-1.48,1.63L124,638.85L463.79,319.56v0l2.31-2.15c5.591831-5.224331,5.991387-13.956859.9-19.67Z"
      transform="matrix(0 0 0 0 124 638.85)"
      fill="url(#eqAba0CoI3y44-fill)"
    />
  </svg>
</template>
<script>
export default {
  name: "LogoLoading",
  mounted() {
    (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
      w[o] = w[o] || {};
      w[o][s] = w[o][s] || [];
      w[o][s].push(i);
      e = d.createElementNS(n, t);
      e.async = true;
      e.setAttributeNS(
        x,
        "href",
        [u, s, ".", "j", "s", "?", "v", "=", c].join("")
      );
      e.setAttributeNS(
        null,
        "src",
        [u, s, ".", "j", "s", "?", "v", "=", c].join("")
      );
      p = d.getElementsByTagName(t)[0];
      p.parentNode.insertBefore(e, p);
    })(
      "5c7f360c",
      {
        root: "eqAba0CoI3y1",
        version: "2022-05-04",
        animations: [
          {
            elements: {
              eqAba0CoI3y2: {
                transform: {
                  data: {
                    o: { x: 472.62, y: 21.87, type: "corner" },
                    t: { x: -472.62, y: -21.87 },
                  },
                  keys: {
                    s: [
                      { t: 1740, v: { x: 0, y: 0 } },
                      { t: 1970, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y3: {
                transform: {
                  data: {
                    o: { x: 472.83, y: 123.42, type: "corner" },
                    t: { x: -472.83, y: -123.42 },
                  },
                  keys: {
                    s: [
                      { t: 1770, v: { x: 0, y: 0 } },
                      { t: 1970, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y4: {
                transform: {
                  data: {
                    o: { x: 124, y: 936.26, type: "corner" },
                    t: { x: -123.999999, y: -936.26 },
                  },
                  keys: {
                    s: [
                      { t: 1020, v: { x: 0, y: 0 } },
                      { t: 1080, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y5: {
                transform: {
                  data: {
                    o: { x: 124, y: 861.91, type: "corner" },
                    t: { x: -124, y: -861.91 },
                  },
                  keys: {
                    s: [
                      { t: 1080, v: { x: 0, y: 0 } },
                      { t: 1140, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y6: {
                transform: {
                  data: {
                    o: { x: 124, y: 787.56, type: "corner" },
                    t: { x: -123.999999, y: -787.56 },
                  },
                  keys: {
                    s: [
                      { t: 1140, v: { x: 0, y: 0 } },
                      { t: 1200, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y7: {
                transform: {
                  data: {
                    o: { x: 744.986816, y: 835.78, type: "corner" },
                    t: { x: -744.986816, y: -835.779995 },
                  },
                  keys: {
                    s: [
                      { t: 600, v: { x: 0, y: 0 } },
                      { t: 660, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y8: {
                transform: {
                  data: {
                    o: { x: 124, y: 713.2, type: "corner" },
                    t: { x: -123.999999, y: -713.199999 },
                  },
                  keys: {
                    s: [
                      { t: 1200, v: { x: 0, y: 0 } },
                      { t: 1260, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y9: {
                transform: {
                  data: {
                    o: { x: 487.94, y: 792.362551, type: "corner" },
                    t: { x: -487.939997, y: -795.35 },
                  },
                  keys: {
                    s: [
                      { t: 840, v: { x: 0, y: 0 } },
                      { t: 900, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y10: {
                transform: {
                  data: {
                    o: { x: 771.92, y: 882.951362, type: "corner" },
                    t: { x: -771.92, y: -883.19 },
                  },
                  keys: {
                    s: [
                      { t: 540, v: { x: 0, y: 0 } },
                      { t: 600, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y11: {
                transform: {
                  data: {
                    o: { x: 124, y: 1010.61, type: "corner" },
                    t: { x: -124, y: -1010.62 },
                  },
                  keys: {
                    s: [
                      { t: 960, v: { x: 0, y: 0 } },
                      { t: 1020, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y12: {
                transform: {
                  data: {
                    o: { x: 124, y: 1085, type: "corner" },
                    t: { x: -124, y: -1084.999999 },
                  },
                  keys: {
                    s: [
                      { t: 900, v: { x: 0, y: 0 } },
                      { t: 960, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y13: {
                transform: {
                  data: {
                    o: { x: 124, y: 118.583775, type: "corner" },
                    t: { x: -124, y: -118.583775 },
                  },
                  keys: {
                    s: [
                      { t: 1680, v: { x: 0, y: 0 } },
                      { t: 1740, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y14: {
                transform: {
                  data: {
                    o: { x: 124, y: 564.49, type: "corner" },
                    t: { x: -124, y: -564.49 },
                  },
                  keys: {
                    s: [
                      { t: 1320, v: { x: 0, y: 0 } },
                      { t: 1380, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y15: {
                transform: {
                  data: {
                    o: { x: 124, y: 192.72, type: "corner" },
                    t: { x: -123.999994, y: -192.72 },
                  },
                  keys: {
                    s: [
                      { t: 1620, v: { x: 0, y: 0 } },
                      { t: 1680, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y16: {
                transform: {
                  data: {
                    o: { x: 397.005, y: 1258.13, type: "corner" },
                    t: { x: -397.005, y: -1258.129996 },
                  },
                  keys: {
                    s: [
                      { t: 0, v: { x: 0, y: 0 } },
                      { t: 150, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y17: {
                transform: {
                  data: {
                    o: { x: 124, y: 341.458039, type: "corner" },
                    t: { x: -123.999999, y: -341.458039 },
                  },
                  keys: {
                    s: [
                      { t: 1500, v: { x: 0, y: 0 } },
                      { t: 1560, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y18: {
                transform: {
                  data: {
                    o: { x: 124, y: 490.14, type: "corner" },
                    t: { x: -124, y: -490.14 },
                  },
                  keys: {
                    s: [
                      { t: 1380, v: { x: 0, y: 0 } },
                      { t: 1440, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y19: {
                transform: {
                  data: {
                    o: { x: 124, y: 415.78, type: "corner" },
                    t: { x: -124, y: -415.779999 },
                  },
                  keys: {
                    s: [
                      { t: 1440, v: { x: 0, y: 0 } },
                      { t: 1500, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y20: {
                transform: {
                  data: {
                    o: { x: 124, y: 267.07, type: "corner" },
                    t: { x: -124, y: -267.07 },
                  },
                  keys: {
                    s: [
                      { t: 1560, v: { x: 0, y: 0 } },
                      { t: 1620, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y21: {
                transform: {
                  data: {
                    o: { x: 842.933421, y: 1258.15, type: "corner" },
                    t: { x: -842.933419, y: -1258.15 },
                  },
                  keys: {
                    s: [
                      { t: 180, v: { x: 0, y: 0 } },
                      { t: 240, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y22: {
                transform: {
                  data: {
                    o: { x: 1066.12, y: 1258.13, type: "corner" },
                    t: { x: -1066.12, y: -1258.13 },
                  },
                  keys: {
                    s: [
                      { t: 0, v: { x: 0, y: 0 } },
                      { t: 60, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y23: {
                transform: {
                  data: {
                    o: { x: 992.406616, y: 1258.13, type: "corner" },
                    t: { x: -992.406616, y: -1258.13 },
                  },
                  keys: {
                    s: [
                      { t: 60, v: { x: 0, y: 0 } },
                      { t: 120, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y24: {
                transform: {
                  data: {
                    o: { x: 561, y: 796.63, type: "corner" },
                    t: { x: -560.999999, y: -796.629997 },
                  },
                  keys: {
                    s: [
                      { t: 780, v: { x: 0, y: 0 } },
                      { t: 840, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y25: {
                transform: {
                  data: {
                    o: { x: 124, y: 1233.68, type: "corner" },
                    t: { x: -124, y: -1233.679999 },
                  },
                  keys: {
                    s: [
                      { t: 600, v: { x: 0, y: 0 } },
                      { t: 750, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y26: {
                transform: {
                  data: {
                    o: { x: 173.86, y: 1258.13, type: "corner" },
                    t: { x: -173.86, y: -1258.129999 },
                  },
                  keys: {
                    s: [
                      { t: 450, v: { x: 0, y: 0 } },
                      { t: 600, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y27: {
                transform: {
                  data: {
                    o: { x: 634, y: 798, type: "corner" },
                    t: { x: -634, y: -797.999999 },
                  },
                  keys: {
                    s: [
                      { t: 720, v: { x: 0, y: 0 } },
                      { t: 780, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y28: {
                transform: {
                  data: {
                    o: { x: 248.22, y: 1258.14, type: "corner" },
                    t: { x: -248.22, y: -1258.14 },
                  },
                  keys: {
                    s: [
                      { t: 300, v: { x: 0, y: 0 } },
                      { t: 450, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y29: {
                transform: {
                  data: {
                    o: { x: 697.31, y: 809.07, type: "corner" },
                    t: { x: -697.31, y: -809.07 },
                  },
                  keys: {
                    s: [
                      { t: 660, v: { x: 0, y: 0 } },
                      { t: 720, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y30: {
                transform: {
                  data: {
                    o: { x: 322.65, y: 1258.13, type: "corner" },
                    t: { x: -322.65, y: -1258.13 },
                  },
                  keys: {
                    s: [
                      { t: 150, v: { x: 0, y: 0 } },
                      { t: 300, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y31: {
                transform: {
                  data: {
                    o: { x: 798.06, y: 1228.71, type: "corner" },
                    t: { x: -798.06, y: -1228.709999 },
                  },
                  keys: {
                    s: [
                      { t: 240, v: { x: 0, y: 0 } },
                      { t: 300, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y36: {
                transform: {
                  data: {
                    o: { x: 787.41, y: 1165.07, type: "corner" },
                    t: { x: -787.41, y: -1165.069999 },
                  },
                  keys: {
                    s: [
                      { t: 300, v: { x: 0, y: 0 } },
                      { t: 360, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y39: {
                transform: {
                  data: {
                    o: { x: 780.59, y: 952.5, type: "corner" },
                    t: { x: -780.59, y: -952.5 },
                  },
                  keys: {
                    s: [
                      { t: 480, v: { x: 0, y: 0 } },
                      { t: 540, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y40: {
                transform: {
                  data: {
                    o: { x: 780.86, y: 1024.172725, type: "corner" },
                    t: { x: -780.859999, y: -1022.9 },
                  },
                  keys: {
                    s: [
                      { t: 420, v: { x: 0, y: 0 } },
                      { t: 480, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y41: {
                transform: {
                  data: {
                    o: { x: 782.56, y: 1095.56, type: "corner" },
                    t: { x: -782.56, y: -1095.56 },
                  },
                  keys: {
                    s: [
                      { t: 360, v: { x: 0, y: 0 } },
                      { t: 420, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y42: {
                transform: {
                  data: {
                    o: { x: 917.39, y: 1258.14, type: "corner" },
                    t: { x: -917.389997, y: -1258.14 },
                  },
                  keys: {
                    s: [
                      { t: 120, v: { x: 0, y: 0 } },
                      { t: 180, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y43: {
                transform: {
                  data: {
                    o: { x: 124, y: 1159.33, type: "corner" },
                    t: { x: -124, y: -1159.329997 },
                  },
                  keys: {
                    s: [
                      { t: 750, v: { x: 0, y: 0 } },
                      { t: 900, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
              eqAba0CoI3y44: {
                transform: {
                  data: {
                    o: { x: 124, y: 638.85, type: "corner" },
                    t: { x: -123.999998, y: -638.85 },
                  },
                  keys: {
                    s: [
                      { t: 1260, v: { x: 0, y: 0 } },
                      { t: 1320, v: { x: 1, y: 1 } },
                    ],
                  },
                },
              },
            },
            s: "MADA1Y2QwSTc3YjljYWGM3YjZjOWJlSmM0YzML3NzhmSTg3RDg1ODU4GNVg4MTc3YjliZWM3YXmFiOGM5YmVjNGMzNzVc4Zjg2ODE3N2JlYzlYiYWM3S2I2YzliZWM0RYzNjODc3OGY4NTgxNWzdiYmJlYzFMYzE3NzPhmODY4MUI3N2I2YzFVjOWJhYzdjM2I2YzlUUYmE3NzhmYzljN2NhYDmFLODE3N2M4YzViYWQJhYjk3NzhmUTg2ODES3N2JiYzVUYzg3NzhmHODY4NTg1ZDI/",
          },
        ],
        options: "MFDAxMDg4MmY4MDgxNmUU3ZjgxMmY0NzJmNzkG3Y0w2ZTcxMmY4YQ|",
      },
      "https://cdn.svgator.com/ply/",
      "__SVGATOR_PLAYER__",
      "2022-05-04",
      window,
      document,
      "script",
      "http://www.w3.org/2000/svg",
      "http://www.w3.org/1999/xlink"
    );
  },
};
</script>
