<template>
  <div
    class="project-box"
    :style="{
      transform:
        'perspective(' +
        rectWidth * 2 +
        'px) rotateX(' +
        xDeg +
        'deg) rotateY(' +
        yDeg +
        'deg) rotateZ(0deg) scale3d(1, 1, 1)',
    }"
    @mouseenter="hovering = true"
    @mousemove="hoverDegs"
    @mouseleave="hoverLeave"
    ref="projectBox"
  >
    <div class="project-text" :class="{ 'project-text-hover': hovering }">
      <!-- <h2 class="project-title" :class="{ 'project-title-hover': hovering }">
        {{ project.title }}
      </h2> -->
      <img
        :src="require(`@/${project.logo}`)"
        alt=""
        class="project-logo"
        :class="{ 'project-logo-hover': hovering }"
      />
      <!-- <p class="project-desc" v-if="hovering">
        {{ project.desc }}
      </p> -->
      <button
        type="button"
        class="modalButton"
        data-bs-toggle="modal"
        data-bs-target="#SiteModal"
        :aria-label="`Preview ${project.title} Site`"
        @click="openModal(project.link)"
        v-if="hovering"
      >
        <span class="buttonText">Preview</span>
        <font-awesome-icon
          class="modal-fa"
          icon="fa-solid fa-maximize"
          size="2x"
        />
      </button>
    </div>

    <img
      class="project-image"
      :src="require(`@/${project.pic}`)"
      :alt="project.title"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProjectBox",
  props: ["project"],
  data() {
    return {
      hovering: false,
      xDeg: 0,
      yDeg: 0,
      rectWidth: 0,

      styleObject: {
        transform:
          "perspective(" +
          this.rectWidth +
          "px) rotateX(" +
          this.xDeg +
          "deg) rotateY(" +
          this.yDeg +
          ") scale3d(1, 1, 1)",
      },
    };
  },
  methods: {
    hoverDegs(e) {
      if (this.hovering) {
        let rectHeight = this.$refs.projectBox.getBoundingClientRect().height;
        let rectWidth = this.$refs.projectBox.getBoundingClientRect().width;
        let leftBox = this.$refs.projectBox.getBoundingClientRect().left;
        let topBox = this.$refs.projectBox.getBoundingClientRect().top;
        let left = e.clientX - leftBox;
        let top = e.clientY - topBox;
        this.rectWidth = rectWidth;
        this.xDeg = 14 * (top / rectHeight) - 7;
        this.yDeg = 10 - 20 * (left / rectWidth);
      }
      // console.log(this.xDeg);
      // console.log(e.clientX);
      // console.log(e.clientY);

      // console.log(e.pageX);
      // console.log(e.pageY);

      // console.log(e.screenX);
      // console.log(e.screenY);
    },
    hoverLeave() {
      // setTimeout(() => {
      //   this.hovering = false;
      //   this.xDeg = 0;
      //   this.yDeg = 0;
      // }, 300);
      this.hovering = false;
      this.xDeg = 0;
      this.yDeg = 0;
    },
    setIframeSite(link) {
      this.$store.dispatch(`modal/setSiteURL`, link);
    },
    openModal(link) {
      this.$store.dispatch(`modal/setSiteURL`, link);
      // document.documentElement.style.overflow = "hidden";
    },
  },
};
</script>

<style lang="scss" scoped>
.project-box {
  width: 33vw;
  height: 33vw;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  position: relative;
  transition: all linear;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  perspective-origin: 50% 50%;
  perspective: 1500px;
  z-index: 1;
  border-radius: 10px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  will-change: transform;
  // filter: drop-shadow(8px 7px 15px #000);
  // box-shadow: 0 0 40px lighten(#000, 60%);
  box-shadow: 5px 5px 15px rgba(#000, 0.5);
  opacity: 1;
  overflow: hidden;
  background: theme-var(
    $--ch-theme-text-opposite
  ); /* fallback for old browsers */
  // background: -webkit-linear-gradient(
  //   90deg,
  //   #dd1818,
  //   #333333
  // );
  // background: linear-gradient(
  //   0deg,
  //   rgba(5, 41, 57, 1) 0%,
  //   rgba(1, 126, 127, 1) 25%,
  //   rgba(70, 181, 155, 1) 50%,
  //   rgba(239, 160, 50, 1) 75%,
  //   rgba(235, 69, 43, 1) 100%
  // );
  // background-image: radial-gradient(
  //   circle farthest-corner at 10% 20%,
  //   rgba(222, 168, 248, 1) 0%,
  //   rgba(168, 222, 248, 1) 21.8%,
  //   rgba(189, 250, 205, 1) 35.6%,
  //   rgba(243, 250, 189, 1) 52.9%,
  //   rgba(250, 227, 189, 1) 66.8%,
  //   rgba(248, 172, 172, 1) 90%,
  //   rgba(254, 211, 252, 1) 99.7%
  // );
  // background-image: linear-gradient(
  //   40deg,
  //   rgba(25, 50, 70, 0.81) 10.7%,
  //   rgba(155, 65, 25, 0.72) 39.5%,
  //   rgba(255, 192, 0, 0.81) 69.7%
  // );

  &:hover {
    // cursor: pointer;
    // box-shadow: 0 14px 20px 0 rgb(80, 80, 80 /24%);
    // background: #000;
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    .project-image {
      opacity: 0.3;
      -webkit-filter: blur(3px); /* Safari 6.0 - 9.0 */
      filter: blur(3px);
      -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%)
        right/300% 100%;
      background-repeat: no-repeat;
      animation: shimmer 2.5s infinite;

      @keyframes shimmer {
        100% {
          -webkit-mask-position: left;
        }
      }
    }
  }
  .project-image {
    opacity: 1;
    display: block;
    width: 100%;
    // height: 100%;
    transition: 0.5s ease;
    backface-visibility: hidden;
    border-radius: 8px;
    object-fit: cover;
    object-position: center top;
    z-index: 2;
  }
  .project-text {
    font-family: $font-main;
    color: theme-var($--ch-theme-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    filter: drop-shadow(8px 7px 23px #000) !important;

    z-index: 3;
    .project-title {
      // position: relative;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      font-family: Bungee;
      transform: translate3d(0, 0, 0);
      transition: 0.4s ease;
      transition-property: transform;
      z-index: 3;
      will-change: transform;
      &-hover {
        transform: translate3d(0, calc(-100% - 1px), 0);
        z-index: 3;
      }
    }
    .project-logo {
      max-width: 100px;
      transition: transform 0.4s ease;
      filter: grayscale(0%) !important;
      &-hover {
        transform: translate(0, -30px);
      }
    }
    .project-desc {
      position: relative;
      z-index: 3;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
    .modalButton {
      position: relative;
      padding: 5px 0;
      width: 200px;
      height: 50px;
      // background: none;
      // border: 1px solid theme-var($--ch-theme-text);
      // color: theme-var($--ch-theme-text);
      border: none;
      background: rgba(255, 255, 255, 0.31);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      transition: all 0.2s linear;
      will-change: background;
      cursor: pointer;
      // backdrop-filter: blur(9.3px);
      // -webkit-backdrop-filter: blur(9.3px);
      .buttonText {
        position: relative;
        bottom: 0px;
        opacity: 1;
        transition: all 0.15s ease-out, color 0.2s linear,
          opacity 0.15s ease-out;
        color: theme-var($--ch-theme-text);
        white-space: nowrap;
        will-change: bottom, color, opacity;
        text-transform: uppercase;
        font-family: $font-header;
        font-size: 25px;
        line-height: 50px;
      }
      .modal-fa {
        color: theme-var($--ch-theme-text);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: all 0.2s cubic-bezier(0.55, 0.06, 0.75, 0.54),
          color 0.2s linear;
        will-change: transform;
      }

      &:hover {
        // background: theme-var($--ch-theme-background-opposite);
        .buttonText {
          bottom: 30px;
          // color: theme-var($--ch-theme-text-opposite);
          opacity: 0;
        }
        .modal-fa {
          // color: theme-var($--ch-theme-text-opposite);
          transform: translate(-50%, -50%) scale(1);

          // -webkit-transform: translateX(-150%) translateY(-50%) rotateY(0)
          //   scale(1);
          // transform: translateX(-150%) translateY(-50%) rotateY(0) scale(1);
          // -webkit-transform-origin: -500px 50%;
          // transform-origin: -500px 50%;
        }
      }
    }
  }
}
</style>
