<template>
  <div class="accordion">
    <div class="accordion" id="accordionBenefit">
      <div class="accordion-item" v-for="item in accordionItems" :key="item.id">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            :style="{
              backgroundImage: `url(https://fakeimg.pl/900x200)`,
            }"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#${item.id}`"
          >
            <!-- backgroundImage: `url(${require(`@/assets/images/tailored-gold/${item?.bgSrc}`)})`, -->
            <h3>
              {{ item.header.top }}
            </h3>
            <h2>
              {{ item.header.bottom }}
            </h2>
          </button>
        </h2>
        <div
          :id="item.id"
          class="accordion-collapse collapse"
          :class="!item.collapse || 'show'"
          data-bs-parent="#accordionBenefit"
        >
          <div class="accordion-body">
            <div class="boxes">
              <Box :box="box" v-for="box in item.body" :key="box.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Box from "./Box";

export default {
  name: "AccordionBenefit",
  components: { Box },
  props: ["accordionItems"],
};
</script>

<style lang="scss" scoped>
.accordion {
  &-body {
    background-color: theme-var($--ch-theme-primary);
  }
  &-item {
    margin-bottom: 50px;
  }
  &-button {
    background-size: cover;
    height: 200px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-transform: uppercase;
    padding-left: 30px;
    & > * {
      position: relative;
      z-index: 1;
    }
    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      position: relative;
      color: theme-var($--ch-theme-on-primary);
      top: 40px;
      img {
        position: absolute;
        left: -13px;
        top: -43px;
        height: 40px;
      }
    }
    h2 {
      font-size: 35px;
      @include media-breakpoint-down(sm) {
        font-size: 23px;
        line-height: 1.4em;
      }
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: -0.03em;
      text-align: left;
      color: theme-var($--ch-theme-highlight);
      margin: 0;
      position: relative;
      top: 40px;
    }
    &:after {
      // background-image: url("~images/arrow-white.png") !important;
      top: -20px;
      right: 20px;
      height: 50px !important;
      width: 50px !important;
      background-size: 100% !important;
      position: relative;
    }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    //   background: linear-gradient(
    //     90deg,
    //     rgba(0, 0, 0, 0.72) 0%,
    //     rgba(90, 20, 20, 0) 100%
    //   );
    //   z-index: 0;
    // }
  }
  .boxes {
    display: flex;
    justify-content: center;
    flex-wrap: no-wrap;
    gap: 10px;
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }
  }
}
</style>
