<template>
  <div class="account_summary" id="account_summary">
    <div class="account_summary_content container">
      <SectionHeader
        topText="account summary &#38;"
        bottomText="payment options"
      />
      <div class="table_title">account summary</div>
      <table class="table table-responsive table-borderless">
        <thead>
          <tr>
            <th scope="col">Event</th>
            <th scope="col">section</th>
            <th scope="col">row</th>
            <th scope="col">seat From</th>
            <th scope="col">seat To</th>
            <th scope="col"># of Seats</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in purl.accounts" :key="account.id">
            <td>{{ account.event }}</td>
            <td>{{ account.section }}</td>
            <td>{{ account.row }}</td>
            <td>{{ account.seatFrom }}</td>
            <td>{{ account.seatTo }}</td>
            <td>{{ account.numOfSeats }}</td>
            <td>{{ account.total === " $-   " ? "Free" : account.total }}</td>
          </tr>
        </tbody>
      </table>
      <div class="table_footer">
        <p>After Credit</p>
        <div class="table_footer_item">
          <div class="left">
            <p>12-Month Payment plan MONTHLY dues</p>
          </div>
          <div class="right">{{ purl.total12 }}</div>
        </div>
        <div class="table_footer_item">
          <div class="left">
            <p>11-Month Payment plan MONTHLY dues</p>
          </div>
          <div class="right">{{ purl.total11 }}</div>
        </div>
        <div class="table_footer_item">
          <div class="left">
            <p>DUe Now</p>
          </div>
          <div class="right">{{ purl.totalDue }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import SectionHeader from "@/components/SectionHeader";

export default {
  name: "Account",
  components: { SectionHeader },

  setup() {
    const store = useStore();

    // State
    const state = reactive({
      purl: computed(() => store.getters["purl/getAccount"]),
    });

    return { ...toRefs(state) };
  },
};
</script>

<style lang="scss" scoped>
.account_summary {
  min-height: 100vh;
  // background-image: url("~images/account/bg.jpeg");
  background-size: cover;
  padding-block: 15vh;
  background-color: theme-var($--ch-theme-background-secondary);

  @include media-breakpoint-down(sm) {
    padding-block: 5vh;
  }
  &_content {
    align-items: center;
    z-index: 1;
    height: 100%;
    .btn-lg {
      font-size: 34px !important;
      @include media-breakpoint-down(sm) {
        font-size: 20px !important;
      }
      padding: 0.3em 3em 0.2em !important;
    }
    .footnote {
      font-size: 13px;
      margin-top: 40px;
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }
    @include media-breakpoint-up(xl) {
      max-width: 890px;
    }
    .table {
      border: 1px solid theme-var($--ch-theme-primary);
    }
    .table_title {
      background-color: theme-var($--ch-theme-primary);
      width: 100%;
      font-family: $font-main;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      padding: 1em;
      text-transform: uppercase;
    }
    .table_footer {
      background-color: theme-var($--ch-theme-primary);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      padding: 10px;
      padding-top: 30px;
      position: relative;
      &_item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      & > p {
        font-family: $font-main;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        position: absolute;
        top: 10px;
      }
      .left {
        p {
          margin: 0;
          text-transform: uppercase;

          &:last-child {
            font-family: $font-main;
            font-size: 16px;
            @include media-breakpoint-down(lg) {
              font-size: 13px;
            }
            @include media-breakpoint-down(md) {
              font-size: 12px;
            }
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0px;
          }
        }
      }
      .right {
        color: theme-var($--ch-theme-highlight);
        font-size: 20px;
        @include media-breakpoint-down(lg) {
          font-size: 13px;
        }
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: -0.03em;
        text-align: right;
      }
    }
    table {
      background: white;
      margin: 0;

      thead {
        th {
          text-transform: uppercase;
          font-family: $font-main;
          font-size: 10px;
          @include media-breakpoint-down(md) {
            font-size: 8px;
            letter-spacing: inherit;
          }
          font-style: normal;
          font-weight: 700;
          line-height: 15px;
          letter-spacing: 1.5px;
          color: #141414a6;
          padding: 1em;
          text-align: center;
          &:first-child {
            text-align: left;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
      tbody {
        td {
          text-transform: uppercase;
          font-family: $font-main;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0.15000000596046448px;
          text-align: left;
          padding: 0.5em;
          text-align: center;
          @include media-breakpoint-down(lg) {
            font-size: 13px;
          }
          @include media-breakpoint-down(md) {
            font-size: 11px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 10px;
            line-height: 1.4em;
          }
          &:first-child {
            text-align: left;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    .accordion_wrapper {
      margin-top: 70px;
      @include media-breakpoint-down(sm) {
        margin-top: 40px;
      }
    }
    .renew {
      display: block;
      margin: auto;
    }
  }
}
</style>
