<template>
  <div class="section_header" :class="{ white }">
    <h2 :class="{ toporange }">{{ topText }}</h2>
    <h1>{{ bottomText }}</h1>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: ["white", "toporange", "topText", "bottomText"],
};
</script>

<style lang="scss" scoped>
.section_header {
  margin-bottom: 60px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }
  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    color: theme-var($--ch-theme-on-primary);
    background-color: theme-var($--ch-theme-secondary);
    display: inline;
    padding: 2px 14px;
    border-radius: 3px;
    text-transform: uppercase;
    // &.toporange {
    //   background-color: #ffb612;
    //   color: #5a1414;
    // }
  }
  h1 {
    font-size: 65px;
    color: theme-var($--ch-theme-on-primary);
    @include media-breakpoint-down(md) {
      font-size: 41px;
      line-height: 1.7em;
    }
    font-style: italic;
    font-weight: 700;
    line-height: 87px;
    letter-spacing: -0.06em;
    text-align: left;
    text-transform: uppercase;
  }
  // &.white {
  //   h1 {
  //     color: white;
  //   }
  // }
}
</style>