<template>
  <div id="experience" class="borderSeperator">
    <div class="experience" data-aos="fade-up">
      <div class="title container">RECENT GIGS</div>
      <div class="experience_content container">
        <VerticalTabNew
          :tabs="tabsList"
          v-if="this.$store.getters['dom/getWindowWidth'] >= 992"
        />
        <!-- <FlipCard
          :stackedItems="tabsList"
          v-if="this.$store.getters['dom/getWindowWidth'] < 992"
        /> -->
        <FlipCard2
          :stackedItems="tabsList"
          v-if="this.$store.getters['dom/getWindowWidth'] < 992"
        />
      </div>
      <div class="resume">
        <a
          href="https://www.ramanheera.com/docs/RamanResume.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="resume-link shadow-pop-bl"
          aria-label="View my Resume"
          ><span>View Full Resume</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { tabs } from "@/assets/data/experienceTabs.js";
import VerticalTabNew from "@/components/VerticalTabNew.vue";
// import FlipCard from "@/components/FlipCard.vue";
import FlipCard2 from "@/components/FlipCard2.vue";

export default {
  name: "Experience",
  components: { VerticalTabNew, FlipCard2 },

  setup() {
    const tabsList = tabs;
    return { tabsList };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.experience {
  .title {
    font-family: $font-header;
    font-size: 4vw;
    // max-width: 800px;
    margin: 0 auto;
    color: theme-var($--ch-theme-text-heading);
    @include media-breakpoint-up(lg) {
      max-width: 800px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 8vw;
    }
    @include media-breakpoint-down(md) {
      font-size: 10vw;
    }
    // font-feature-settings: "dlig", "liga", "calt";
    // font-variant-ligatures: common-ligatures discretionary-ligatures contextual;
    // font-feature-settings: "smcp" 1;
    // font-variant-ligatures: none;
  }
  .experience_content {
    .experience-text-cnt {
      .experience-text {
      }
    }
  }
  .resume {
    display: flex;
    justify-content: center;
    &-link {
      font-family: $font-main;
      color: theme-var($--ch-theme-text-dark);
      font-size: 18px;
      letter-spacing: 0.5px;
      text-decoration: none;
      width: 240px;
      height: 72px;
      // line-height: 1.1111111111111112vw;
      border: 2px solid theme-var($--ch-theme-text-dark);
      // padding: 1.1805555555555556vw 2.5vw;
      text-align: center;
      box-sizing: border-box;
      position: relative;
      background: #eae2d9;
      box-shadow: -1px 1px theme-var($--ch-theme-text-dark),
        -2px 2px theme-var($--ch-theme-text-dark),
        -3px 3px theme-var($--ch-theme-text-dark),
        -4px 4px theme-var($--ch-theme-text-dark),
        -5px 5px theme-var($--ch-theme-text-dark),
        -6px 6px theme-var($--ch-theme-text-dark),
        -7px 7px theme-var($--ch-theme-text-dark),
        -8px 8px theme-var($--ch-theme-text-dark);
      transform: translateX(8px) translateY(-8px);
      transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: -8px 8px theme-var($--ch-theme-text-dark),
          -9px 9px theme-var($--ch-theme-text-dark),
          -10px 10px theme-var($--ch-theme-text-dark),
          -11px 11px theme-var($--ch-theme-text-dark),
          -12px 12px theme-var($--ch-theme-text-dark),
          -13px 13px theme-var($--ch-theme-text-dark),
          -14px 14px theme-var($--ch-theme-text-dark),
          -15px 15px theme-var($--ch-theme-text-dark);
        transform: translateX(15px) translateY(-15px);
      }
    }
  }
}
#experience {
  margin: 0 auto;
  // margin-block: 20vh;
  padding: 120px 0;
  // border-top: 2px solid #000;
  // border-width: 2px;
  // border-style: solid;
  // border-image: linear-gradient(
  //     to right,
  //     rgba(0, 0, 0, 0),
  //     rgba(0, 0, 0, 0),
  //     rgba(0, 0, 0, 0.5),
  //     black,
  //     rgba(0, 0, 0, 0.5),
  //     rgba(0, 0, 0, 0),
  //     rgba(0, 0, 0, 0)
  //   )
  //   1;
  // border-bottom: none;
  // border-left: none;
  // border-right: none;
  // border-bottom: 2px solid #000;

  @include media-breakpoint-down(sm) {
    margin-block: 5vh;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1040px;
  }
  .breakLine {
    border-top: 2px solid #000;
    width: 80vw;
    max-width: 80vw;
    margin: 0 auto;
  }
}
</style>
