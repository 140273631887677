<template>
  <div
    class="modal fade-scale"
    id="SiteModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Site Modal"
    aria-hidden="true"
    ref="siteModal"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> -->
        <div class="modal-body">
          <iframe
            :src="$store.state.modal.iframeURL"
            title="description"
            class="site-iFrame"
          ></iframe>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="close"
      data-bs-dismiss="modal"
      aria-label="Close Modal"
    >
      <font-awesome-icon class="x-icon-fa" icon="fa-solid fa-xmark" size="2x" />
    </button>
  </div>
</template>

<script>
import { onMounted } from "vue";
import $ from "jquery";

var jQuery = require("jquery");
window.jQuery = jQuery;
window.$ = jQuery;
export default {
  name: "WebModal",
  setup() {
    function startScroll() {
      $("#SiteModal").on("hide.bs.modal", function () {
        document.documentElement.style.overflow = "auto";
      });
    }
    function stopScroll() {
      $("#SiteModal").on("show.bs.modal", function () {
        document.documentElement.style.overflow = "hidden";
      });
    }
    onMounted(() => {
      stopScroll();
      startScroll();
    });
  },
};
</script>
<style lang="scss" scoped>
.modal {
  .close {
    position: absolute;
    top: 1vw;
    right: 1vw;
    background: none;
    border: none;
    .x-icon-fa {
      color: #fff;
    }
  }
  &-lg {
    max-width: 90vw;
    max-height: 90vh;
    min-height: 90vh;
    margin: 3rem auto;
  }
  &-content {
    min-height: 90vh;
    background-color: theme-var($--ch-theme-text-opposite);
  }
  &-body {
    display: flex;
    .site-iFrame {
      flex-grow: 1;
      border: none;
      margin: 0;
      padding: 0;
    }
  }
  &-open {
    :deep(html) {
      overflow: hidden;
    }
  }
}
.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  will-change: transform, opacity;
}

.fade-scale.show {
  opacity: 1;
  transform: scale(1);
}
</style>
