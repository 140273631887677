<template>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary" v-on:click="printhandler">
          Print
        </button>
      </div>
      <div class="modal-body"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInvoice",
  components: {},
  methods: {
    printhandler: function (event) {
      let routeData = this.$router.resolve({
        name: "InvoicePrint",
        params: this.$route.params,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 0;
}
.modal-header {
  justify-content: flex-end;
  .btn {
    margin: 0 10px;
  }
}
</style>
