<template>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <vimeo-player
          ref="player"
          autoplay
          :videoId="`${videoId}`"
          class="video"
          :options="{ responsive: true }"
          :play="videoIsPlaying"
          @playing="onPlaying"
          @pause="onStop"
          @ended="onStop"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "ModalVideo",
  setup() {
    const store = useStore();
    const player = ref(null);

    function onPlaying() {
      store.dispatch(`modal/setVideoIsPalying`, true);
    }

    function onStop() {
      store.dispatch("modal/setVideoIsPalying", false);
    }

    const videoIsPlaying = computed(() => store.state.modal.videoIsPlaying);
    const videoId = computed(() => store.state.modal.videoId);

    watch(videoIsPlaying, (isPlaying) => {
      if (!isPlaying) player.value.pause();
    });

    return {
      onStop,
      onPlaying,
      videoIsPlaying,
      videoId,
      player,
      store,
    };
  },
};
</script>