<template>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header border-0">
        <!-- <h5 class="modal-title" id="mainModalLabel">Terms & conditions</h5> -->
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <!-- <img src="~images/terms-conditions/t-c1.jpg" alt="" />
        <img src="~images/terms-conditions/t-c2.jpg" alt="" />
        <img src="~images/terms-conditions/t-c3.jpg" alt="" />
        <img src="~images/terms-conditions/t-c4.jpg" alt="" />
        <img src="~images/terms-conditions/t-c5.jpg" alt="" />
        <img src="~images/terms-conditions/t-c6.jpg" alt="" />
        <img src="~images/terms-conditions/t-c7.jpg" alt="" /> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <!-- <button type="button" class="btn btn-primary">Renew my seats!</button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Renew",
};
</script>

<style lang="scss" scoped>
.modal-body img {
  width: 100%;
}
</style>
