<template>
  <div id="hero"></div>
</template>

<script>
export default {
  name: "Hero",
  setup() {},
};
</script>
<style lang="scss" scoped>
.hero {
}
</style>
