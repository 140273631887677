<template>
  <div id="projects">
    <div class="projects">
      <div class="projects_content">
        <div class="panels-cnt">
          <div
            class="all-container"
            :style="{ width: projectsList.length * 100 + '%' }"
            id="panel-container"
          >
            <div class="bg-header" id="projects-title">
              Projects
              <font-awesome-icon
                class="arrow-fa"
                icon="fa-solid fa-person-walking-arrow-right"
              />
            </div>
            <!-- <SectionHeader
              topText="Professional"
              bottomText="Projects"
              :white="true"
            /> -->
            <section class="panel start-panel"></section>
            <section
              class="panel"
              v-for="(project, index) in projectsList"
              :project="project"
              :key="project.title"
              :class="index === projectsList.length - 1 ? 'green' : null"
            >
              <ProjectBox :project="project" :key="project.title" />
            </section>
            <!-- <div class="panel blue">
              Scroll down to animate horizontally &gt;
            </div>

            <section class="panel red">
              <div>Fire an animation at a particular spot...</div>
              <div class="box-1 box">box-1</div>
            </section>

            <section class="panel gray">
              <div>...or scrub it back &amp; forth with the scrollbar</div>
              <div class="box-2 box">box-2</div>
            </section>
            <section class="panel purple">
              <div>Toggle a CSS class</div>
              <div class="box-3 box">box-3</div>
            </section>
            <section class="panel green">
              <div>Use the rich callback system</div>
            </section> -->
          </div>

          <!-- <div class="final">
            <div>
              <h1>Wasn't that fun?</h1>
              <p>Here are a few caveats to keep in mind:</p>
              <ul>
                <li>
                  The fake-scrolling animation (just the part that's moving the
                  container horizontally) must have no easing (<code
                    >ease: "none"</code
                  >).
                </li>
                <li>
                  Pinning and snapping won't work on ScrollTriggers with a
                  <code>containerAnimation</code>.
                </li>
                <li>
                  The mapping of scroll position trigger points are based on the
                  trigger element itself not being animated horizontally (inside
                  the container). If you need to animate the trigger, you can
                  either wrap it in a &lt;div&gt; and use that as the trigger
                  instead or just factor the trigger's movement into your end
                  position. For example, if you animate it left 100px, make the
                  <code>end</code> 100px further to the left.
                </li>
                <li>Requires ScrollTrigger 3.8.0 or later</li>
              </ul>
              <div class="bounce-button-cnt">
                <a href="" class="bounce-button">Test Me</a>
              </div>
            </div>
          </div> -->
          <!-- <div class="project-box-test">
            <ProjectBox
              v-for="project in projectsList"
              :project="project"
              :key="project.title"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SectionHeader from "@/components/SectionHeader";
import ProjectBox from "@/pages/Home/Projects/ProjectBox.vue";
import { projects } from "@/assets/data/projects.js";
import { onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "Projects",
  components: { ProjectBox },

  setup() {
    // // State
    const projectsList = projects;
    function horScroll() {
      gsap.registerPlugin(ScrollTrigger);

      let sections = gsap.utils.toArray(".panel");

      let scrollTween = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none", // <-- IMPORTANT!
        scrollTrigger: {
          trigger: ".all-container",
          pin: true,
          scrub: 1,
          // start: "-70",
          start: "center center",
          // snap: {
          //   snapTo: 1 / (sections.length - 1),
          //   inertia: false,
          //   duration: { min: 0.5, max: 0.5 },
          // },
          snap: {
            // snapTo: 1 / (sections.length - 1),
            // snapTo: "labels", // snap to the closest label in the timeline
            // duration: { min: 0.2, max: 2 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            // delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
            // ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
          },
          //snap: directionalSnap(1 / (sections.length - 1)),
          end: "+=3000",
        },
      });

      sections.forEach((section, i) => {
        ScrollTrigger.create({
          trigger: section,
          containerAnimation: scrollTween,
          toggleClass: "active-project",
          start: "left center",
          end: "left start",
        });
      });

      // gsap.set(".box-1, .box-2", { y: 100 });
      // ScrollTrigger.defaults({
      //   markers: { startColor: "white", endColor: "white" },
      // });

      // gsap.to("#projects-title", {
      //   scrollTrigger: {
      //     trigger: "#panel-container",
      //     markers: true,
      //     start: "top top",
      //     end: "+=400",
      //     pin: "#panel-container",
      //     scrub: 2,
      //   },
      //   opacity: 1,
      //   y: 200,
      // });
      // red section
      // gsap.to(".box-1", {
      //   y: 0,
      //   duration: 2,
      //   ease: "elastic",
      //   scrollTrigger: {
      //     trigger: ".box-1",
      //     containerAnimation: scrollTween,
      //     start: "left center",
      //     toggleActions: "play none none reset",
      //     id: "1",
      //   },
      // });

      // gray section
      // gsap.to(".box-2", {
      //   y: 0,
      //   backgroundColor: "#1e90ff",
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: ".box-2",
      //     containerAnimation: scrollTween,
      //     start: "center 80%",
      //     end: "center 20%",
      //     scrub: true,
      //     id: "2",
      //   },
      // });

      // purple section
      // ScrollTrigger.create({
      //   trigger: ".box-3",
      //   containerAnimation: scrollTween,
      //   toggleClass: "active",
      //   start: "left center",
      //   id: "3",
      // });

      // green section
      ScrollTrigger.create({
        trigger: ".green",
        containerAnimation: scrollTween,
        start: "center 65%",
        end: "center 51%",
        // onEnter: () => console.log("enter"),
        // onLeave: () => console.log("leave"),
        // onEnterBack: () => console.log("enterBack"),
        // onLeaveBack: () => console.log("leaveBack"),
        // onToggle: (self) => console.log("active", self.isActive),
        id: "4",
      });

      // only show the relevant section's markers at any given time
      // gsap.set(
      //   ".gsap-marker-start, .gsap-marker-end, .gsap-marker-scroller-start, .gsap-marker-scroller-end",
      //   { autoAlpha: 0 }
      // );
      // ["red", "gray", "purple", "green"].forEach((triggerClass, i) => {
      //   ScrollTrigger.create({
      //     trigger: "." + triggerClass,
      //     containerAnimation: scrollTween,
      //     start: "left",
      //     end: i === 3 ? "right right" : "right 30%",
      //     markers: false,
      //     onToggle: (self) =>
      //       gsap.to(".marker-" + (i + 1), {
      //         duration: 0.25,
      //         autoAlpha: self.isActive ? 1 : 0,
      //       }),
      //   });
      // });

      // helper function for causing the sections to always snap in the direction of the scroll (next section) rather than whichever section is "closest" when scrolling stops.
      // function directionalSnap(increment) {
      //   let snapFunc = gsap.utils.snap(increment);
      //   return (raw, self) => {
      //     let n = snapFunc(raw);
      //     return Math.abs(n - raw) < 1e-4 || (n < raw) === self.direction < 0 ? n : self.direction < 0 ? n - increment : n + increment;
      //   };
      // }
    }
    onMounted(() => {
      horScroll();
    });
    return { projectsList };
  },
};
</script>

<style lang="scss" scoped>
.projects {
  // background-image: url("~images/contact/bg.jpeg");
  //   background-position: right;
  //   background-size: cover;
  //   display: flex;
  //   position: relative;
  // background-color: theme-var($--ch-theme-background-secondary);

  &_content {
    align-items: center;
    // margin-block: 30vh;
    @include media-breakpoint-down(sm) {
      margin-top: 5vh;
    }
    z-index: 1;
    @include media-breakpoint-up(xl) {
      //   max-width: 890px;
    }
    .bg-header {
      // vertical-align: middle;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 3.4vw;
      font-size: 10rem;
      color: theme-var($--ch-theme-text-heading);

      // text-align: center;
      // height: 90vh;
      // line-height: 90vh;
      // color: #fcedd8;
      // background: #d52e3f;
      font-family: $font-header;
      font-weight: 700;
      text-transform: uppercase;
      font-variant-ligatures: common-ligatures discretionary-ligatures
        contextual;
      // text-shadow: 5px 5px 0px #eb452b, 10px 10px 0px #efa032,
      //   15px 15px 0px #46b59b, 20px 20px 0px #017e7f, 25px 25px 0px #052939,
      //   30px 30px 0px #c11a2b;

      // left: 50%;
      // transform: translate(-50%, -50%);
    }
    .panels-cnt {
      // .box {
      //   width: 100px;
      //   height: 80px;
      //   text-align: center;
      //   line-height: 80px;
      //   background-color: white;
      //   border-radius: 8px;
      //   color: #222;
      //   font-weight: 700;
      //   margin-left: 20px;
      //   will-change: transform;
      // }
      // .box.active {
      //   background-color: orange;
      //   border: 2px solid white;
      // }
      .description,
      .final {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        min-height: 80vh;
      }
      .description {
        position: relative;
      }

      .all-container {
        // width: 500%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        top: 0;
      }

      .panel {
        width: 50vw;
        height: 33vw;
        display: flex;
        justify-content: center;
        will-change: transform;
        // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        // filter: grayscale(100%);
        // margin: 0 auto;
        // height: 50vh;
        // opacity: 0.5;
        &.active-project {
          .project-box {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
            transition: filter 0.4s ease;
          }
        }
        &.start-panel {
          width: 75vw;
          // background: rgba($color: #000000, $alpha: 0.4);
          background: none;
        }
      }
      .project-box-test {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
