<template>
  <div class="container">
    <div class="d-flex justify-content-center">
      <div
        ref="refModal"
        @click="`hidden.bs.modal`"
        class="modal fade"
        id="mainModal"
        tabindex="-1"
      >
        <component :is="component" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

import ModalTemplate from "@/components/ModalTemplate";
import ModalInvoice from "@/components/ModalInvoice";
import TCModal from "@/components/TCModal";
import ModalVideo from "@/components/ModalVideo";

export default {
  name: "UiModal",
  setup() {
    const store = useStore();
    const refModal = ref();

    // State
    const state = reactive({
      component: computed(() => {
        const component = store.state.modal.component;
        switch (component) {
          case "invoice":
            return ModalInvoice;
          case "TC":
            return TCModal;
          case "modalVideo":
            return ModalVideo;
          default:
            return ModalTemplate;
        }
      }),
    });
    onMounted(() => {
      refModal.value.addEventListener("hidden.bs.modal", function () {
        store.dispatch("modal/setVideoIsPalying", false);
        store.dispatch("modal/setVideoId", null);
      });
    });

    return { ...toRefs(state), refModal };
  },
};
</script>