<template>
  <div class="footer">
    <div class="rep container">
      <div class="left"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {},
  setup() {},
};
</script>

<style lang="scss" scoped>
.footer {
}
</style>
