<template>
  <div id="intro">
    <div class="intro">
      <div class="intro_content container">
        <div class="intro-text-cnt">
          <div class="name-cnt">
            <p class="intro-text intro-text-name">
              Hi, I'm <span class="highlighted-text">Raman</span> (Ruh•min)
              <button
                aria-label="Play Name Pronunciation"
                class="speaker-cnt"
                @click="playName()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="500"
                  height="500"
                  viewBox="0 0 75 75"
                  class="speaker"
                >
                  <path
                    d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z"
                    class="speaker-box"
                    style="stroke-width: 5; stroke-linejoin: round"
                  />
                  <path
                    d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6"
                    class="speaker-lines"
                    style="stroke-width: 5; stroke-linecap: round"
                  />
                </svg>
              </button>
            </p>
          </div>
          <p class="intro-text intro-text-copy">
            A <span class="highlighted-text">Frontend Developer</span> with a
            love of design and a passion for creating
            <span class="highlighted-text">memorable</span> web experiences. I'm
            enthusiastic about building
            <span class="highlighted-text">engaging</span> products that provide
            value to all, from
            <span class="highlighted-text">non-profits</span> to
            <span class="highlighted-text">large businesses</span>.
          </p>
        </div>

        <!-- <img
          src="@/assets/images/icons/Speaker_Icon.svg"
          alt="Speaker"
          class="speaker"
        /> -->
        <!-- <inline-svg
          :src="require('@/assets/images/icons/Speaker_Icon.svg')"
          fill="white"
        ></inline-svg> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  setup() {
    let audioPlaying = false;

    return { audioPlaying };
  },
  methods: {
    playName() {
      if (!this.audioPlaying) {
        this.audioPlaying = true;
        var audio = new Audio(require("@/assets/images/icons/ruhmin.mp3"));
        audio.play();
        setTimeout(() => {
          this.audioPlaying = false;
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.intro_content {
  .intro-text-cnt {
    .intro-text {
      font-size: clamp(30px, 6vw, 2em);
      font-family: $font-main;
      margin-bottom: 0;
      letter-spacing: 0.5px;
      &-name {
        font-size: clamp(40px, 8vw, 3em);
      }
      .speaker-cnt {
        // display: flex;
        align-items: center;
        background: none;
        border: none;
        // max-width: 40px;
        // max-height: 40px;
        @include media-breakpoint-only(xs) {
          padding: 0;
        }
        .speaker {
          max-width: 40px;
          max-height: 40px;
          &-box,
          &-lines {
            fill: theme-var($--ch-theme-text);
            stroke: theme-var($--ch-theme-text);
          }
          &-lines {
            fill: none;
          }
        }
        &:hover {
          -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
          animation: pulsate-bck 0.5s ease-in-out infinite both;
          @include media-breakpoint-only(xs) {
            animation: none;
          }
          @-webkit-keyframes pulsate-bck {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
            50% {
              -webkit-transform: scale(0.9);
              transform: scale(0.9);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
          @keyframes pulsate-bck {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
            50% {
              -webkit-transform: scale(0.9);
              transform: scale(0.9);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        }
      }
      .highlighted-text {
        background: linear-gradient(
          -45deg,
          theme-var($--ch-theme-grad1),
          theme-var($--ch-theme-grad1),
          theme-var($--ch-theme-grad2),
          theme-var($--ch-theme-grad2)
        );
        background-size: 300%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: animated_text 10s ease-in-out infinite;
        -moz-animation: animated_text 10s ease-in-out infinite;
        -webkit-animation: animated_text 10s ease-in-out infinite;
        &::selection {
          -webkit-text-fill-color: theme-var($--ch-theme-text);
        }
        @keyframes animated_text {
          0% {
            background-position: 0px 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0px 50%;
          }
        }
      }
    }
    .name-cnt {
      display: flex;
    }
  }
}
#intro {
  margin: 0 auto;
  margin-block: 20vh;
  @include media-breakpoint-down(sm) {
    margin-block: 5vh;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1040px;
  }
}
</style>
