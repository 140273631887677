<template>
  <div>
    <div class="scene scene--card">
      <div class="card" :class="{ flipme: cardOne == 'flipped' }">
        <div class="card__face card__face--front">
          <button
            v-for="(item, index) in stackedItems"
            :key="'item' + index"
            class="item arrowButton"
            :class="['item-' + index]"
            @click="itemClick(index)"
          >
            <span class="buttonText">{{ item.mobileTitle }} </span>
            <font-awesome-icon
              class="arrow-fa"
              icon="fa-solid fa-arrow-right-long"
              size="2x"
            />
          </button>
        </div>
        <div class="card__face card__face--back">
          <h3>{{ currentItem.role }}</h3>
          <p>{{ currentItem.time }}</p>
          <ul v-html="currentItem.desc"></ul>
          <button @click="cardOne = 'start'" class="item-left backButton">
            <span class="buttonText">Back</span>
            <font-awesome-icon
              class="arrow-fa"
              icon="fa-solid fa-arrow-left-long"
              size="2x"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlipCard2",
  props: { stackedItems: Array },

  data() {
    return {
      cardOne: "start",
      currentItem: this.stackedItems[0],
    };
  },
  methods: {
    itemClick(item) {
      this.currentItem = this.stackedItems[item];
      this.cardOne = "flipped";
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  font-family: sans-serif;
}

.scene {
  /* width: 500px;
  height: 400px; */
  background: none;
  margin: 0 auto;
  padding-bottom: 20px;
  perspective: 600px;
  -webkit-perspective: 600px;
}

.card {
  /* width: 100%;
  height: 100%; */
  /* transition: transform 1s; */
  min-height: 500px;
  //   transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  //   transition-duration: 1s;
  //   transition-property: transform;
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  position: relative;
  background: none;
  border: none;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
}
.item {
  position: relative;
  padding: 5px 0;
  margin: 5px 0;
  flex-basis: calc(20% - 10px);
  // background: none;
  // border: 1px solid theme-var($--ch-theme-text);
  // color: theme-var($--ch-theme-text);
  border: none;
  background: rgba(255, 255, 255, 0.31);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;
  // will-change: background;
  // backdrop-filter: blur(9.3px);
  // -webkit-backdrop-filter: blur(9.3px);
  .buttonText {
    font-family: $font-main;
    position: relative;
    margin-left: 0px;
    opacity: 1;
    transition: all 0.3s ease-out, color 0.2s linear, opacity 0.4s ease-in;
    color: theme-var($--ch-theme-text);
    white-space: nowrap;
    will-change: margin-left, opacity;
    user-select: none;
  }
  .arrow-fa {
    color: theme-var($--ch-theme-text);
    user-select: none;

    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-1100%, -50%);
    //   -webkit-transform: translateX(400px) translateY(0) rotateY(-30deg)
    //     scale(0);
    //   transform: translateX(400px) translateY(0) rotateY(-30deg) scale(0);
    //   -webkit-transform-origin: -100% 50%;
    //   transform-origin: -100% 50%;
    transition: all 0.3s cubic-bezier(0.55, 0.06, 0.75, 0.54), color 0.2s linear;
    will-change: transform, opacity;
  }

  &:hover {
    // background: theme-var($--ch-theme-background-opposite);
    .buttonText {
      margin-left: 50%;
      // color: theme-var($--ch-theme-text-opposite);
      opacity: 0;
    }
    .arrow-fa {
      opacity: 1 !important;
      // color: theme-var($--ch-theme-text-opposite);
      transform: translate(-50%, -50%);

      // -webkit-transform: translateX(-150%) translateY(-50%) rotateY(0)
      //   scale(1);
      // transform: translateX(-150%) translateY(-50%) rotateY(0) scale(1);
      // -webkit-transform-origin: -500px 50%;
      // transform-origin: -500px 50%;
    }
  }
}
.card__face--front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__face--back {
  /* background: blue; */
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  font-family: $font-main;

  .backButton {
    min-height: 50px;
    width: 100%;
    color: theme-var($--ch-theme-text);
    position: relative;
    padding: 5px 0;
    flex-basis: calc(20% - 10px);
    // background: none;
    // border: 1px solid theme-var($--ch-theme-text);
    // color: theme-var($--ch-theme-text);
    border: none;
    background: rgba(255, 255, 255, 0.31);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.2s linear;
    // will-change: background;
    // backdrop-filter: blur(9.3px);
    // -webkit-backdrop-filter: blur(9.3px);
    .buttonText {
      position: relative;
      margin-right: 0px;
      opacity: 1;
      transition: all 0.3s ease-out, color 0.2s linear, opacity 0.4s ease-in;
      color: theme-var($--ch-theme-text);
      white-space: nowrap;
      will-change: margin-right, opacity;
    }
    .arrow-fa {
      color: theme-var($--ch-theme-text);

      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      transform: translate(1100%, -50%);
      //   -webkit-transform: translateX(400px) translateY(0) rotateY(-30deg)
      //     scale(0);
      //   transform: translateX(400px) translateY(0) rotateY(-30deg) scale(0);
      //   -webkit-transform-origin: -100% 50%;
      //   transform-origin: -100% 50%;
      transition: all 0.3s cubic-bezier(0.55, 0.06, 0.75, 0.54),
        color 0.2s linear;
      will-change: transform, opacity;
    }

    &:hover {
      // background: theme-var($--ch-theme-background-opposite);
      .buttonText {
        margin-right: 50%;
        // color: theme-var($--ch-theme-text-opposite);
        opacity: 0;
      }
      .arrow-fa {
        opacity: 1 !important;
        // color: theme-var($--ch-theme-text-opposite);
        transform: translate(-50%, -50%);

        // -webkit-transform: translateX(-150%) translateY(-50%) rotateY(0)
        //   scale(1);
        // transform: translateX(-150%) translateY(-50%) rotateY(0) scale(1);
        // -webkit-transform-origin: -500px 50%;
        // transform-origin: -500px 50%;
      }
    }
  }
}

/* this style is applied when the card is clicked */
.flipme {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
</style>
