<template>
  <div v-if="(!errorMsg && !isLoading) || isDev" id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>

  <teleport to="body">
    <!-- <UiToastList />
    <UiModal /> -->
    <LoadingScreen
      v-if="!isDev && isLoading"
      :isLoading="isLoading"
      :errorMsg="errorMsg"
    />
  </teleport>
</template>

<script>
import AppHeader from "./Header.vue";
// import AppFooter from "./Footer.vue";
// import UiToastList from "@/components/UiToastList";
// import UiModal from "@/components/UiModal";
import LoadingScreen from "@/components/LoadingScreen";
import VueScrollTo from "vue-scrollto";

export default {
  name: "AppLayout",
  components: {
    AppHeader,
    // AppFooter,
    // UiToastList,
    // UiModal,
    LoadingScreen,
  },
  data() {
    return {
      isLoading: false,
      errorMsg: "",
      isDev: process.env.NODE_ENV === "development",
      // isDev: false,
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;

      setTimeout(() => {
        {
          setTimeout(() => {}, 200);
        }
        this.isLoading = false;
      }, 2000);
    },
  },
  beforeUnmount: () => {
    window.removeEventListener("resize", () =>
      this.$store.commit("dom/SET_WINDOW_WIDTH", window.innerWidth)
    );
  },
  mounted() {
    // this.fetchData();
  },
};
</script>
<style lang="scss">
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.intro-enter-active,
.intro-leave-active {
  transition: opacity 0.8s;
}
.intro-enter,
.intro-leave-to {
  opacity: 0;
}
</style>
