<template>
  <div class="flipCard-cnt" :class="[{ flipped: showCard === true }]">
    <div class="front">
      <button
        v-for="(item, index) in stackedItems"
        :key="'item' + index"
        class="item"
        :class="[{ active: activeItem === index }, 'item-' + index]"
        @click="itemClick(index)"
      >
        {{ item.mobileTitle }}
      </button>
    </div>
    <div class="back" v-show="showCard">
      <h3>{{ currentItem.role }}</h3>
      <p>{{ currentItem.time }}</p>
      <ul v-html="currentItem.desc"></ul>
      <button @click="showCard = false">Back</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlipCard",
  props: { stackedItems: Array },
  data() {
    return {
      showCard: false,
      activeItem: 0,
      currentItem: this.stackedItems[0],
    };
  },

  methods: {
    itemClick(item) {
      this.activeItem = item;
      this.currentItem = this.stackedItems[this.activeItem];
      this.showCard = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.flipCard-cnt {
  position: relative;
  min-height: 350px;
  .front,
  .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
  }
  .front {
    transform: rotateY(0deg);
    display: flex;
    flex-direction: column;
  }
  .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
  }
  &.flipped {
    .front {
      transform: rotateY(180deg);
    }
    .back {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  &.flip-vertical {
    .back {
      transform: rotateX(-180deg);
    }
    &:hover {
      .front {
        transform: rotateX(180deg);
      }
      .back {
        transform: rotateX(0deg);
      }
    }
  }
}
</style>
