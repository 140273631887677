/**
 * anything that relates to DOM
 */
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    /**
     * breakpoint constants
     */
    widthExtraSmall: 320,
    widthMobile: 480,
    widthTablet: 768,
    topMenuOffset: 70,
    topMenuOffsetMobile: 70,
    theme: "light",
  },

  mutations: {
    SET_WINDOW_WIDTH(state, value) {
      state.windowWidth = value;
    },
    SET_THEME(state, value) {
      state.theme = value;
      document.documentElement.className = value;
    },
  },

  getters: {
    // isExtraSmall: (state) => state.windowWidth <= state.widthExtraSmall,
    // isMobile: (state) => state.windowWidth <= state.widthMobile,
    // isTablet: (state) => state.windowWidth <= state.widthTablet,
    // isDesktop: (state) => state.windowWidth > state.widthTablet,
    topMenuOffset: (state) =>
      state.windowWidth < 992 ? state.topMenuOffsetMobile : state.topMenuOffset,
    getTheme: (state) => state.theme,
    getWindowWidth: (state) => state.windowWidth,
  },
};
