export default {
  namespaced: true,
  state: {
    iframeURL: "",
  },
  actions: {
    setSiteURL({ commit, dispatch }, url) {
      commit("SET_SITE_URL", url);
    },
  },
  mutations: {
    SET_SITE_URL(state, url) {
      state.iframeURL = url;
    },
  },

  getters: {},
};
