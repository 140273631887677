<template>
  <div class="intro">
    <div class="video">
      <vimeo-player
        ref="player"
        :video-id="644998104"
        @ended="skipVideo"
        :options="{ responsive: true, background: true }"
      />
    </div>
    <div class="video_text">
      <button
        type="button"
        class="btn btn-primary btn-lg px-4 gap-3"
        data-bs-toggle="modal"
        data-bs-target="#mainModal"
        @click.prevent="
          skipVideo();
          $store.dispatch(`modal/setComponentName`, `modalVideo`);
          $store.dispatch(`modal/setVideoId`, 644998104);
        "
      >
        Watch
      </button>
      <button
        type="button"
        class="btn btn-primary btn-lg px-4 gap-3"
        @click.prevent="skipVideo"
      >
        Skip Intro
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroVideo",
  props: ["skipVideo"],
};
</script>

<style lang="scss" scoped>
.intro {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 40;
  background: theme-var($--ch-theme-primary);
  .video {
    width: 100% !important;
    height: 100vh !important;
    min-width: 1400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    & > div {
      transform: scale(1.5);
      @include media-breakpoint-down(sm) {
        transform: scale(1.2);
      }
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #0000006b;
    }
    &_text {
      position: absolute;
      z-index: 1;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100px;
      @include media-breakpoint-down(sm) {
        bottom: 200px;
      }
      display: flex;
      gap: 20px;
      h2 {
        font-family: Formula1-Display-Bold;
        padding-block: 15px;
        font-size: 58px;
        color: #ffffff;
        text-align: center;
        text-shadow: 0 9px 4px rgba(0, 0, 0, 0.5);
        font-weight: 500;
        text-transform: uppercase;
        @media (max-width: 900px) {
          font-size: 30px;
        }
        @media (max-width: 500px) {
          font-size: 20px;
        }
      }
    }
  }
}
</style>