<template>
  <div class="box">
    <div class="content-front">
      <div class="cf-inner">
        <div class="inner">
          <h2>{{ box.frontText }}</h2>
          <img src="https://fakeimg.pl/80x80" alt="" />
          <!-- <img
            :src="require(`@/assets/images/tailored-gold/icons/${box.icon}`)"
            alt=""
          /> -->
        </div>
      </div>
    </div>
    <div class="content-back">
      <div class="cf-inner">
        <div class="inner">
          <h4>
            {{ box.backText }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Box",
  props: ["box"],
};
</script>

<style lang="scss" scoped>
.box {
  height: 270px;
  @include media-breakpoint-down(sm) {
    height: 180px;
  }
  flex: 1;
  position: relative;
  // transform-style: preserve-3d;
  // perspective: 1000px;
  backface-visibility: hidden;
  transition: 0.9s ease;
  @include media-breakpoint-down(lg) {
    flex: 0 0 calc(50% - 5px);
  }
  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }
  .content-front,
  .content-back {
    position: relative;
    // transform-style: preserve-3d;
    // perspective: 1000px;
    backface-visibility: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-front {
    background-color: theme-var($--ch-theme-secondary);
    padding: 10px;
    border: 4px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    padding: 1em 2em;
  }
  .content-back {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: rotateY(180deg);
    background: theme-var($--ch-theme-black);
    color: white;
    padding: 1em;
  }
  &:hover {
    .content-front {
      transform: rotateY(-180deg);
    }
    .content-back {
      transform: rotateY(0deg);
    }
  }
  &:first-child {
    .cf-inner {
      img {
        width: 120px;
        height: 50px;
        object-fit: contain;
      }
    }
  }
  .cf-inner {
    // transform-style: preserve-3d;
    backface-visibility: hidden;
    width: 100%;
    .inner {
      align-items: stretch;
      // transform-style: preserve-3d;
      // perspective: 1200px;
      backface-visibility: hidden;
      // transform: translateZ(55px) scale(0.9);
      text-align: center;
      position: relative;
      z-index: 2;
    }
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
    h2 {
      font-family: $font-main;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      padding-bottom: 20px;
      // text-transform: uppercase;
    }
    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  & > * {
    transition: 0.9s ease;
  }
}
</style>
