<template>
  <div id="benefit_bottom">
    <div class="benefit_bottom">
      <div class="benefit_bottom_content container">
        <SectionHeader topText="Experience" bottomText="" />
        <div class="copy">
          <h6>(tap on each card for more information)</h6>
        </div>
        <div class="accordion_wrapper">
          <AccordionBenefit :accordionItems="accordionItems" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import AccordionBenefit from "./AccordionBenefit";

export default {
  name: "BenefitBottom",
  components: { SectionHeader, AccordionBenefit },
  data() {
    return {
      accordionItems: [
        {
          id: "channel-1",
          collapse: true,
          bgSrc: "exp1.jpeg",
          titleLogo: "Gardens-wII.png",
          header: {
            top: "Channel 1 Media Solutions Inc.",
            bottom: "2021-Present",
          },
          body: [
            {
              id: 0,
              frontText: "Private Day at Busch Gardens",
              backText:
                "Enjoy a private day at the world’s most beautiful theme park with your fellow Channel1 Gold Family and Alumni ",
              icon: "GardensII.png",
            },
            {
              id: 1,
              frontText: "Family Photo Shoot",
              backText:
                "Take your annual family holiday picture at the 50-yard line of FedExField!",
              icon: "Photoshoot.png",
            },
            {
              id: 2,
              frontText: "Family Movie Night",
              backText:
                "Come out to FedExField to enjoy a family-friendly movie under the lights!",
              icon: "movie-night.png",
            },
            {
              id: 3,
              frontText: "Family Field Day",
              backText:
                "Show off your football skills with your friends and family postgame on the field where your favorite Channel1 players play! ",
              icon: "Fieldday.png",
            },
          ],
        },
        {
          id: "business-network",
          collapse: true,
          bgSrc: "exp2.jpeg",
          header: {
            top: "Channel1 Gold",
            bottom: "business network",
          },
          body: [
            {
              id: 0,
              frontText: "Taste of the NFL ",
              backText:
                "Come experience tastings from some of the top culinary offerings in the NFL ",
              icon: "Taste.png",
            },
            {
              id: 1,
              frontText: "VIP Access to Channel1 Football Team Events",
              backText:
                "Experience exclusive opportunities at  Channel1 Training Camp and Draft Party including VIP access, complimentary food and drink, and special meet-and-greet opportunities ",
              icon: "VIP.png",
            },
            {
              id: 2,
              frontText: "Rebrand All-Access",
              backText:
                "Peak behind the curtain of the team’s rebrand process with Team President Jason Wright ",
              icon: "Curtain.png",
            },
          ],
        },
        {
          id: "Ultra",
          collapse: true,
          bgSrc: "exp3.jpeg",
          header: {
            top: "Channel1 Gold",
            bottom: "Ultra",
          },
          body: [
            {
              id: 0,
              frontText: "Football & Film Features",
              backText:
                "Film sessions with Channel1 Coaches and Front Office Personnel",
              icon: "Film.png",
            },
            {
              id: 1,
              frontText: "Gold Member Team Store Takeover",
              backText:
                "Private shopping event at the Channel1 Football Team Store only available to Channel1 Gold Ultra Members! Stock up on new branded merchandise with a special one-day discount!",
              icon: "Proshop.png",
            },
            {
              id: 2,
              frontText: "Fantasy Football Frenzy ",
              backText:
                "Host your personal fantasy football draft in a suite at FedExField! Channel1 Alumni will be on-hand serving as special advisors in a fully catered private suite ",
              icon: "Fantasy.png",
            },
            {
              id: 3,
              frontText: "Away Game Watch Party",
              backText:
                "Cheer on the Burgundy & Gold during an away game with complimentary food and beverage, alumni meet & greets, and other special opportunities. ",
              icon: "Away.png",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.benefit_bottom {
  // background-image: url("~images/tailored-gold/bg.jpeg");
  // background-size: cover;
  background-color: theme-var($--ch-theme-background-secondary);
  display: flex;
  position: relative;

  &_content {
    align-items: center;
    margin-block: 15vh;
    @include media-breakpoint-down(sm) {
      margin-block: 5vh;
    }
    z-index: 1;
    @include media-breakpoint-up(xl) {
      max-width: 890px;
    }
    .copy {
      width: 80%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        span {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        text-transform: uppercase;
      }
      h6 {
        text-align: center;
        margin: 2em 0 1em;
        text-transform: uppercase;
        display: none;
        font-size: 12px;
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
    .accordion_wrapper {
      margin-top: 50px;
    }
  }
}
.perks {
  // background-image: url("~images/tailored-gold/perhs-bg.jpeg");
  // background-position: right;
  // background-size: cover;
  background-color: theme-var($--ch-theme-background-primary);
  display: flex;
  position: relative;
  &_content {
    align-items: center;
    margin-block: 25vh;
    @include media-breakpoint-down(sm) {
      margin-block: 5vh;
    }
    z-index: 1;
    .copy {
      width: 50%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      ul {
        li {
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          text-align: left;
          margin-bottom: 20px;
          // list-style-image: url("~images/tailored-gold/arrow.png");
          // padding-inline-start: 20px;
        }
      }
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .accordion_wrapper {
      margin-top: 50px;
    }
  }
}
</style>
