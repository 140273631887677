export const projects = [
  {
    title: "Chicago Cubs",
    desc: "Cubs site that I worked on, it's really cool trust me",
    link: "https://cubs.fanportal-mlb.com/welcome/rick.amos.0000",
    pic: "assets/images/projects/cubs.webp",
    logo: "assets/images/projects/logo-cubs.webp",
  },
  {
    title: "Los Angeles Clippers",
    desc: "Clippers site that I worked on, it's really cool trust me",
    link: "https://clippers.vipfanportal.com/2022newsales/",
    pic: "assets/images/projects/clippers.webp",
    logo: "assets/images/projects/logo-clippers.webp",
  },
  {
    title: "76ers Welcome",
    desc: "76ers site that I worked on, it's really cool trust me",
    link: "https://76ers.vipfanportal.com/2022/rick.amos.0000",
    pic: "assets/images/projects/76ers.webp",
    logo: "assets/images/projects/logo-76ers.webp",
  },
  {
    title: "Devils Welcome",
    desc: "Devils site that I worked on, it's really cool trust me",
    link: "https://devils.vipfanportal.com/2022/rick.amos.0000",
    pic: "assets/images/projects/devils.webp",
    logo: "assets/images/projects/logo-devils.webp",
  },
  {
    title: "Bears Crib Club",
    desc: "Bears Crib Club site that I worked on, it's really cool trust me",
    link: "https://bears.vipfanportal.com/cribclub/",
    pic: "assets/images/projects/cribclub.webp",
    logo: "assets/images/projects/logo-bears.webp",
  },
  {
    title: "Rangers Suite",
    desc: "Rangers Suite site that I worked on, it's really cool trust me",
    link: "https://rangers.fanportal-mlb.com/2022suites/",
    pic: "assets/images/projects/suite-rentals.webp",
    logo: "assets/images/projects/logo-rangers.webp",
  },
  {
    title: "Cardinals Christmas",
    desc: "Micro Site to with Cardinals fans Happy Holidays!",
    link: "https://cardinals.vipfanportal.com/2021holidays/",
    pic: "assets/images/projects/cardinals.webp",
    logo: "assets/images/projects/logo-cardinals.webp",
  },
];
