<template>
  <div id="faq">
    <div class="faq">
      <div class="faq_content container">
        <SectionHeader topText="2022 Renewal" bottomText="FAQ" />
        <div class="accordion_wrapper">
          <div class="accordion">
            <div class="accordion" id="accordionFaq">
              <div
                class="accordion-item"
                v-for="item in accordionItems"
                :key="item.id"
              >
                <div v-if="item.id !== `eight` || hasClubContract">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#${item.id}`"
                    >
                      <h3>{{ item.header }}</h3>
                    </button>
                  </h2>
                  <div
                    :id="item.id"
                    class="accordion-collapse collapse"
                    :class="!item.collapse || 'show'"
                    data-bs-parent="#accordionFaq"
                  >
                    <div class="accordion-body">
                      <p v-html="$sanitize(item.body)"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import SectionHeader from "@/components/SectionHeader";

export default {
  naem: "Faq",
  components: { SectionHeader },
  data() {
    return {
      accordionItems: [
        {
          id: "zero",
          collapse: true,
          header: "What’s new for the 2022 season?",
          body: /* html */ `
   <strong>We are excited to announce a variety of additions to your Gold Membership for the 2022 season:</strong><br /><br />
    <ul>
        <li>Brand new seating technology and premium seating experiences in select areas of the Club Level </li>
        <li>Personalized Gold Membership experiences tailored to how best you use your tickets </li>
        <li>Interest-free, extended monthly payment options with an annual Gold Membership re-enrollment </li>
        <li>Exclusive opportunity to receive new team-branded renewal perks and incentives</li>
        <li>Complimentary parking for all Club Level Gold Memberships</li>
      </ul>
`,
        },
        {
          id: "one",
          header:
            "What does it mean when I select my tailored membership type?",
          body: "Selecting your tailored membership type will unlock a variety of experiences and benefits based on how best you use your tickets.  You will be invited to a variety of year-round events centered around your membership type with other Washington Gold Members who have also selected the same tailored Gold membership. ",
        },
        {
          id: "two",
          header:
            "Can I change my membership type after I select? If so, when and how?",
          body: "If you would like more information on how to change your membership type, please contact your Membership Services Representative.",
        },
        {
          id: "three",
          header:
            "How do I attend other events or add additional events to my membership?",
          body: "You will be personally invited and able to attend only the events and experiences that fall within your selected membership type.  We will continue to evaluate the membership experiences on an annual basis.  ",
        },
        {
          id: "four",
          header: "Is my ticket pricing changing? ",
          body: "No! All pricing is staying flat or decreasing. In fact, over 11,000 seats in the Club and Upper Level will see a price decrease going into the 2022 season.",
        },
        {
          id: "five",
          header: "Is my parking pricing changing? ",
          body: "All Gold Members will see the same prices for their 2022 parking, with the exception of our Club Level Members. All Club Members will receive complimentary parking with their 2022 renewal!<br/><sub>- Reserved parking excluded</sub>",
        },
        {
          id: "six",
          header: "What is the re-enrollment payment option?",
          body: "This worry-free benefit will make renewing your tickets even easier! As part of the extended 12-month and 11-month payment options this year, the annual re-enrollment element is like your favorite subscription service and will automatically re-enroll your Gold Membership with us every year.",
        },
        {
          id: "seven",
          header:
            "If I sign up for re-enrollment, how can I cancel my membership in the future?",
          body: "Every year, there will be an opt-out period if you would like to select a different payment option or cancel your membership in the future. This will be communicated via email to all Washington Gold Members on an annual basis once pricing for the following season is announced.",
        },
        {
          id: "eight",
          header:
            "Do I have to do anything to confirm my contract elimination? ",
          body: "No! Your invoice will automatically reflect the decrease in price for your 2022 membership. Moving forward, you will receive the same pricing as all other Washington Club Gold Members in a given season.",
        },
      ],
    };
  },
  setup() {
    const store = useStore();

    // State
    const state = reactive({
      hasClubContract: computed(() =>
        store.getters["purl/getLetterVersion"]?.includes("Club Contract")
      ),
    });

    return { ...toRefs(state) };
  },
};
</script>

<style lang="scss" scoped>
.faq {
  // background-image: url("~images/faq/bg.jpeg");
  background: theme-var($--ch-theme-primary);
  background-size: cover;
  display: flex;
  position: relative;

  &_content {
    align-items: center;
    margin-block: 15vh;
    @include media-breakpoint-down(sm) {
      margin-block: 5vh;
    }
    z-index: 1;
    @include media-breakpoint-up(xl) {
      max-width: 890px;
    }
    .accordion {
      &_wrapper {
        margin-top: 50px;
      }
      &-item {
        margin-bottom: 20px;
        border-radius: 3px;
        border: none;
      }
      &-button {
        color: white;
        background: theme-var($--ch-theme-highlight);
        h3 {
          font-family: $font-main;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          text-transform: uppercase;
          margin: 0;
        }
      }
    }
  }
}
</style>
