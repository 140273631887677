import { createApp } from "vue";
import AppLayout from "./layout/index.vue";
import router from "./router";
import store from "./store";
import { registerScrollSpy } from "vue3-scroll-spy";
import VueScrollTo from "vue-scrollto";
import Vue3Sanitize from "vue-3-sanitize";
import BootstrapVue3 from "bootstrap-vue-3";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import {
  faEnvelope,
  faArrowRightLong,
  faArrowLeftLong,
  faShare,
  faPersonWalkingArrowRight,
  faXmark,
  faMaximize,
  faVolumeHigh,
} from "@fortawesome/free-solid-svg-icons";

// import { InlineSvgPlugin } from "vue-inline-svg";
// Botstrap styles
library.add(
  faLinkedinIn,
  faEnvelope,
  faArrowRightLong,
  faArrowLeftLong,
  faShare,
  faPersonWalkingArrowRight,
  faXmark,
  faMaximize,
  faVolumeHigh
);

const app = createApp(AppLayout);
registerScrollSpy(app);
app.use(store);
app.use(router);
app.use(Vue3Sanitize);
app.use(BootstrapVue3);
// app.use(AOS);
app.component("font-awesome-icon", FontAwesomeIcon);

// app.use(InlineSvgPlugin);
app.use(VueScrollTo, {
  container: "body",
  easing: "ease",
  offset: -store.getters["dom/topMenuOffset"],
  duration: 1000,
  force: true,
  cancelable: true,
  x: false,
  y: true,
});
router.isReady().then(() => {
  app.mount("#app");
  store.commit("dom/SET_WINDOW_WIDTH", window.innerWidth);
  window.addEventListener("resize", () =>
    store.commit("dom/SET_WINDOW_WIDTH", window.innerWidth)
  );
  document.documentElement.className = store.getters["dom/getTheme"];
});

// Bootstrap JS
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
