<template>
  <nav class="navbar" id="navbar">
    <section
      class="top_nav container"
      v-if="store.getters['dom/getWindowWidth'] <= 992"
    >
      <div class="logo">
        <a href="#" v-scroll-to="'#intro'" aria-label="Return to top of page">
          <img :src="require(`@/assets/images/${logoSrc}`)" alt="Logo" />
        </a>
      </div>
      <!-- <div class="mode-toggle" @click="modeToggle" :class="theme">
        <div class="toggle">
          <div id="dark-mode" type="checkbox"></div>
        </div>
      </div> -->
    </section>

    <section class="main_nav">
      <div>
        <div class="burger-cnt container">
          <button
            type="button"
            class="burger"
            :class="{ active: isActive }"
            @click="toggleBurger"
            aria-label="Burger Menu Button"
          >
            <span class="burger-line"></span>
            <span class="burger-line"></span>
            <span class="burger-line"></span>
            <span class="burger-line"></span>
          </button>
        </div>

        <div class="menu container" :class="{ active: isActive }" ref="menuRef">
          <section
            class="logo"
            v-if="store.getters['dom/getWindowWidth'] > 992"
          >
            <a href="#" v-scroll-to="'#intro'" aria-label="Go to Intro Section">
              <img :src="require(`@/assets/images/${logoSrc}`)" alt="" />
            </a>
          </section>
          <ul class="menu-inner" v-scroll-spy-active v-scroll-spy-link>
            <li class="menu-item">
              <a
                href="#"
                v-scroll-to="'#intro'"
                class="menu-link"
                aria-label="Return to top of page"
                >Home</a
              >
            </li>
            <li class="menu-item">
              <a
                href="#"
                class="menu-link"
                v-scroll-to="'#experience'"
                aria-label="Go to Experience Section"
                >Experience</a
              >
            </li>
            <li class="menu-item">
              <a
                href="#"
                v-scroll-to="'#projects'"
                class="menu-link"
                aria-label="Go to Projects Section"
                >Projects</a
              >
            </li>

            <li class="menu-item">
              <a
                href="#"
                class="menu-link"
                v-scroll-to="'.contact_us'"
                aria-label="Go to Contact Section"
                >Contact</a
              >
            </li>
            <div
              class="mode-toggle"
              @click="modeToggle"
              :class="theme"
              v-if="store.getters['dom/getWindowWidth'] <= 992"
            >
              <div class="toggle">
                <div id="dark-mode" type="checkbox"></div>
              </div>
            </div>
          </ul>
          <div
            v-if="store.getters['dom/getWindowWidth'] > 992"
            class="mode-toggle"
            @click="modeToggle"
            :class="theme"
          >
            <div class="toggle">
              <div id="dark-mode" type="checkbox"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </nav>
</template>

<script>
import { ref, reactive, watch, toRefs, computed, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  name: "Header",
  setup() {
    const menuRef = ref();
    const store = useStore();

    // State
    const state = reactive({
      isActive: false,
      theme: computed(() => store.getters["dom/getTheme"]),
      logoSrc: computed(() =>
        store.getters["dom/getTheme"] === "light"
          ? "Logos/LogoDark.webp"
          : "Logos/LogoLight.webp"
      ),
    });

    watch(state, () => {
      if (state.isActive) {
        menuRef.value.style.maxHeight = menuRef.value.scrollHeight + 127 + "px";
        document.documentElement.style.overflow = "hidden";
        // document.body.classList.add("disableScroll");
        // document.body.bind("touchmove", function (e) {
        //   e.preventDefault();
        // });
      } else {
        menuRef.value.removeAttribute("style");
        document.documentElement.style.overflow = "auto";
        // document.body.classList.remove("disableScroll");
        // document.body.unbind("touchmove");
      }
    });

    // Methods
    function toggleBurger() {
      state.isActive = !state.isActive;
    }
    function modeToggle() {
      const mode = state.theme === "light" ? "dark" : "light";
      store.commit("dom/SET_THEME", mode);
    }
    function hideNav() {
      var lastScrollTop;
      let navbar = document.getElementById("navbar");
      window.addEventListener("scroll", function () {
        var scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop && !state.isActive) {
          if (scrollTop < 5) {
            navbar.style.top = "0";
          } else {
            navbar.style.top = "-71px";
          }
        } else {
          navbar.style.top = "0";
        }
        lastScrollTop = scrollTop;
      });
    }
    function toTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    onMounted(() => {
      hideNav();
    });
    return {
      ...toRefs(state),
      toggleBurger,
      menuRef,
      store,
      modeToggle,
    };
  },
};
</script>

<style lang="scss" scoped>
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.navbar {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0 !important;
  border: none;
  outline: none;
  background: theme-var($--ch-theme-nav-bg);
  // background: rgba(255, 255, 255, 0.2);
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: fixed !important;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  transition: 0.5s;
  .top_nav {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    @include media-breakpoint-down(lg) {
      justify-content: space-between;
    }
    a {
      font-size: 10px;
      font-weight: 700;
      @include media-breakpoint-down(sm) {
        font-size: 8px;
      }
      line-height: 15px;
      text-align: center;
      color: theme-var($--ch-theme-on-primary);
      text-transform: uppercase;
      text-decoration: none;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
  .logo {
    margin: 10px 0;
    text-align: center;

    img {
      height: 50px;
      // margin-top: -25px;
      border-radius: 0px;
      // @include media-breakpoint-down(sm) {
      //   height: auto;
      //   width: 57%;
      // }
    }
    @include media-breakpoint-only(md) {
      position: relative;
      left: clamp(-8em, -1.5em, -1em);
    }
    @include media-breakpoint-only(sm) {
      position: relative;
      left: clamp(-6em, -1.5em, -1em);
    }
    // @include media-breakpoint-down(sm) {
    //   left: 10px;
    // }
  }
  .main_nav {
    // background: theme-var($--ch-theme-primary);
    width: 100%;

    .menu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // --color-primary: #f6aca2;
      // --color-secondary: #f49b90;
      // --color-tertiary: #f28b7d;
      // --color-quaternary: #f07a6a;
      // --color-quinary: #ee6352;
      // --color-primary: #efa032;
      // --color-secondary: #eb452b;
      // --color-tertiary: #46b59b;
      // --color-quaternary: #017e7f;
      --color-primary: #efa032;
      --color-secondary: #052939;
      --color-tertiary: #052939;
      --color-quaternary: #052939;
      --color-quinary: #052939;
      &-item {
        text-align: center;
        line-height: 0.9em;
        padding: 0.5em 1px;
        @keyframes move {
          0% {
            transform: translate(0px, 0px);
          }
          20% {
            transform: translate(-1px, -1px);
          }
          40% {
            transform: translate(-2px, -2px);
          }
          60% {
            transform: translate(-3px, -3px);
          }
          80% {
            transform: translate(-4px, -4px);
          }
          100% {
            transform: translate(-4px, -4px);
          }
        }
        @keyframes shadows {
          0% {
            text-shadow: none;
            // transform: translate(0px, 0px);
          }
          20% {
            text-shadow: 1px 1px 0 var(--color-secondary);
          }
          40% {
            text-shadow: 1px 1px 0 var(--color-secondary),
              2px 2px 0 var(--color-tertiary);
          }
          60% {
            text-shadow: 1px 1px 0 var(--color-secondary),
              2px 2px 0 var(--color-tertiary), 3px 3px var(--color-quaternary);
          }
          80% {
            text-shadow: 1px 1px 0 var(--color-secondary),
              2px 2px 0 var(--color-tertiary), 3px 3px var(--color-quaternary),
              4px 4px 0 var(--color-quinary);
            // transform: translate(-4px, -4px);
          }
          100% {
            text-shadow: 1px 1px 0 var(--color-secondary),
              2px 2px 0 var(--color-tertiary), 3px 3px var(--color-quaternary),
              4px 4px 0 var(--color-quinary);
            // transform: translate(-4px, -4px);
          }
        }

        &.router-link-active,
        &:hover {
          // text-decoration: underline;
          // text-decoration-color: theme-var($--ch-theme-highlight);
          // color: theme-var($--ch-theme-highlight);
          // text-decoration: underline;
          // animation: shadows 0.6s ease-in -0.5s, move 0.6s ease-in 0s;
          // animation-fill-mode: forwards;
          cursor: pointer;
          .menu-link {
            &:after {
              width: 100%;
              left: -1%;
            }
          }
        }
        // &:not(:hover) {
        //   animation: shadows 0.6s ease-in -0.5s, move 0.6s ease-in 0s;
        //   animation-direction: reverse;
        // }
        &.active {
          // color: theme-var($--ch-theme-highlight);
          // animation: shadows 0.6s ease-in -0.5s, move 0.6s ease-in 0s;
          // animation-fill-mode: forwards;
          cursor: pointer;
          .menu-link {
            &:after {
              width: 100%;
              left: -1%;
            }
          }
        }
      }
      &-link {
        font-family: $font-nav;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 20px;
        text-align: center;
        margin: 0;
        color: theme-var($--ch-theme-text);
        // color: var(--color-primary);
        letter-spacing: 0.3rem;
        position: relative;
        &:after {
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: "";
          display: block;
          height: 2px;
          left: 50%;
          position: absolute;
          background: theme-var($--ch-theme-text);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
      }
      &-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
        list-style: none;
        margin: 0;
        @include media-breakpoint-down(lg) {
          flex-direction: column;
          gap: 0;
          width: 100%;
          padding: 0;
          // margin-top: 70px;
        }
      }
    }
  }

  .burger {
    position: absolute;
    display: none;
    cursor: pointer;
    width: 2rem;
    height: 1rem;
    border: none;
    outline: none;
    opacity: 0;
    visibility: hidden;
    background: none;
    transform: rotate(0deg);
    transition: 0.35s ease-in-out;
    top: 31px;
    right: 25px;
    @include media-breakpoint-down(lg) {
      display: block;
      opacity: 1;
      visibility: visible;
    }
    &-line {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      border: none;
      outline: none;
      opacity: 1;
      border-radius: 0.25rem;
      background: theme-var($--ch-theme-text);
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 0.5rem;
      }

      &:nth-child(4) {
        top: 1rem;
      }
    }

    &.active {
      .burger-line {
        &:nth-child(1),
        &:nth-child(4) {
          top: 1.25rem;
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .menu {
    @include media-breakpoint-down(lg) {
      width: 100%;
      max-height: 0rem;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: all 0.35s ease;
      justify-content: center;
      align-items: flex-start;
    }
    &.active {
      @include media-breakpoint-down(lg) {
        opacity: 1;
        visibility: visible;
      }
    }
    &-inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      list-style: none;
      margin: 0;
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.75rem;
        padding: 1rem 0;
      }
    }
    &-link {
      font-size: 0.7rem;
      font-weight: 500;
      line-height: inherit;
      border: none;
      text-transform: uppercase;
      text-decoration: none;
      text-rendering: optimizeLegibility;
      transition: all 0.35s ease-in-out;
      color: theme-var($--ch-theme-on-secondary);
      &.router-link-active,
      &:hover {
        border: none;
        outline: none;
        // color: theme-var($--ch-theme-highlight);
      }
      @include media-breakpoint-down(lg) {
        width: 100%;
        br {
          display: none;
        }
      }
    }
  }
  .mode-toggle {
    position: relative;
    //margin: auto .5rem .5rem auto;
    padding: 0;
    width: 44px;
    height: 24px;
    min-width: 36px;
    min-height: 20px;
    background-color: theme-var($--ch-theme-secondary);
    border: 1px solid rgb(220, 220, 220);
    border-radius: 24px;
    outline: 0;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    appearance: none;
    transition: background-color 0.5s ease;
    // margin-left: 20px;
    // @include media-breakpoint-down(lg) {
    //   position: absolute;
    //   top: 20px;
    //   right: 25px;
    // }

    .toggle {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 3px solid transparent;
      box-shadow: inset 0 0 0 2px theme-var($--ch-theme-on-secondary);
      overflow: hidden;
      transition: transform 0.5s ease;
      &:before {
        content: "";
        position: relative;
        width: 100%;
        height: 100%;
        left: 50%;
        float: left;
        background-color: theme-var($--ch-theme-on-secondary);
        transition: border-radius 0.5s ease, width 0.5s ease, height 0.5s ease,
          left 0.5s ease, transform 0.5s ease;
      }
    }
    &.dark {
      // background-color: theme-var($--ch-theme-secondary);
      .toggle {
        transform: translateX(19px);
        &:before {
          border-radius: 50%;
          width: 150%;
          height: 85%;
          left: 40%;
          transform: translate(-10%, -40%), rotate(-35deg);
        }
      }
    }
  }
}
</style>
