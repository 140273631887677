<template>
  <div>
    <AppHeader></AppHeader>
    <SideBand v-if="store.getters['dom/getWindowWidth'] >= 992" />
    <div
      class="home"
      v-scroll-spy="{ offset: 300 }"
      :style="{ marginTop: `${$store.getters['dom/topMenuOffset']}px` }"
    >
      <Intro />
      <Experience />
      <Projects v-if="store.getters['dom/getWindowWidth'] >= 992" />
      <ProjectsMobile v-if="store.getters['dom/getWindowWidth'] < 992" />
      <ContactUs />
    </div>
    <WebModal />
  </div>
</template>
<script>
import AppHeader from "@/layout/Header.vue";
// import AppFooter from "@/layout/Footer.vue";
// import Hero from "./Hero";
import Intro from "./Intro";
import Projects from "./Projects";
import ProjectsMobile from "@/pages/Home/Projects/indexMobile.vue";
import Experience from "./Experience";
// import BenefitTop from "./BenefitTop";
// import BenefitBottom from "./BenefitBottom";
// import ImportantDates from "./ImportantDates";
// import NewFor2020 from "./NewFor2020";
// import Faq from "./Faq";
import ContactUs from "./ContactUs";
import { reactive, computed, onBeforeMount, onMounted, watch } from "vue";
import { useStore } from "vuex";
import SideBand from "@/components/SideBand";
import WebModal from "@/components/WebModal";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  components: {
    AppHeader,
    // AppFooter,
    // Hero,
    Intro,
    Experience,
    Projects,
    ProjectsMobile,
    // BenefitTop,
    // BenefitBottom,
    // ImportantDates,
    // NewFor2020,
    // Faq,
    ContactUs,
    SideBand,
    WebModal,
  },
  setup() {
    const lightColors = reactive([
      "#fcedd8",
      "#f5a295",
      "#f7cf98",
      "#a2dbcd",
      "#b3fefe",
      "#ade1f8",
    ]);
    const darkColors = reactive([
      "#052939",
      "#013f3f",
      "#235a4d",
      "#5e3a07",
      "#3f0e06",
      "#573507",
    ]);
    const store = useStore();
    const state = reactive({
      theme: computed(() => store.getters["dom/getTheme"]),
      bgColors: computed(() =>
        store.getters["dom/getTheme"] === "light" ? lightColors : darkColors
      ),
    });
    watch(state, () => {
      // bgChange();
      handleIcon();
    });
    onBeforeMount(() => {
      const prefersDarkMode = window.matchMedia(
        "(prefers-color-scheme:dark)"
      ).matches;
      const mode = prefersDarkMode === true ? "dark" : "light";
      store.commit("dom/SET_THEME", mode);
    });
    onMounted(() => {
      // bgChange();
      // scrollBand();
      handleIcon();
      AOS.init();
    });
    function bgChange() {
      var body = document.getElementsByTagName("body")[0];
      var html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      body.style.transition = "background 3s ease";
      body.style["will-change"] = "background-color";
      body.style.backgroundColor = state.bgColors[0];
      window.onscroll = function (event) {
        var scroll = window.pageYOffset;
        if (scroll < height * 0.14) {
          // green
          body.style.backgroundColor = state.bgColors[0];
        } else if (scroll >= height * 0.14 && scroll < height * 0.3) {
          // yellow
          body.style.backgroundColor = state.bgColors[1];
        } else if (scroll >= height * 0.29 && scroll < height * 0.43) {
          // blue
          body.style.backgroundColor = state.bgColors[2];
        } else if (scroll >= height * 0.43 && scroll < height * 0.57) {
          // orange
          body.style.backgroundColor = state.bgColors[3];
        } else if (scroll >= height * 0.57 && scroll < height * 0.71) {
          // red
          body.style.backgroundColor = state.bgColors[4];
        } else if (scroll >= height * 0.71 && scroll < height * 0.85) {
          // red
          body.style.backgroundColor = state.bgColors[5];
        } else if (scroll >= height * 0.85) {
          // red
          body.style.backgroundColor = state.bgColors[3];
        } else {
          // purple
          body.style.backgroundColor = state.bgColors[0];
        }
      };
    }
    function handleIcon() {
      // accessing the link tag
      const favicon = document.getElementById("favicon");
      store.getters["dom/getTheme"] === "light"
        ? (favicon.href = "https://www.ramanheera.com/favicon.ico")
        : (favicon.href = "https://www.ramanheera.com/favicon-light.ico");
    }

    return {
      store,
    };
  },
};
</script>

<style lang="scss" scoped></style>
