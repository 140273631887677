<template>
  <div class="vertical-tabs-cnt">
    <!-- <div class="tabs" v-if="this.$store.getters['dom/getWindowWidth'] > 992"> -->
    <div class="tabs">
      <button
        v-for="(tab, index) in tabs"
        :key="'tab' + (index + 1)"
        class="tab"
        :class="[{ active: activeTab === index + 1 }, 'tab-' + (index + 1)]"
        :data-tab="index + 1"
        @click="tabClick(index + 1)"
        :aria-label="`View ${tab.title} experience`"
      >
        {{ tab.title }}
      </button>

      <span class="highlighter"></span>
    </div>

    <!-- <div
      class="tabs tabs-small"
      v-if="this.$store.getters['dom/getWindowWidth'] <= 992"
    >
      <button
        v-for="(tab, index) in tabs"
        :key="'tab' + (index + 1)"
        class="tab"
        :class="[{ active: activeTab === index + 1 }, 'tab-' + (index + 1)]"
        :data-tab="index + 1"
        @click="tabClick(index + 1)"
      >
        {{ tab.title }}
      </button>

      <span class="highlighter"></span>
    </div> -->

    <div class="content">
      <div
        v-for="(tab, index) in tabs"
        :key="'content' + (index + 1)"
        class="content__section"
        :class="[{ visible: activeTab === index + 1 }]"
        :data-tab="index + 1"
      >
        <h3>{{ tab.role }}</h3>
        <p>{{ tab.time }}</p>
        <ul v-html="tab.desc"></ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerticalTabNew",
  props: { tabs: Array },
  data() {
    return {
      activeTab: 1,
    };
  },

  methods: {
    tabClick(tab) {
      if (this.activeTab === tab) {
        return;
      } else {
        this.activeTab = tab;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vertical-tabs-cnt {
  //   max-width: 800px;
  //   margin: 0 auto;
  $spacing: 20px;
  $tab-height: 40px;
  position: relative;
  margin: $spacing auto;
  padding: $spacing;
  max-width: 800px;
  display: flex;
  align-items: flex-start;
  min-height: 354px;
  @include media-breakpoint-only(xs) {
    flex-direction: column;
  }
  .tabs {
    display: block;
    position: relative;
    z-index: 3;
    width: max-content;
    .tab {
      font-family: $font-main;
      // text-transform: uppercase;
      text-decoration: none;
      text-decoration-skip-ink: auto;
      transition: color 0.25s ease;
      width: 100px;
      height: $tab-height;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 1px;
      padding: 10px 0;
      transition: all 0.25s ease;
      color: theme-var($--ch-theme-text);
      position: relative;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 0px 20px 2px;
      border-top: none;
      border-right: none;
      border-bottom: none;
      border-left: 3px solid theme-var($--ch-theme-text);
      border-radius: 0;
      background-color: transparent;

      text-align: left;
      white-space: nowrap;
      &:hover {
        color: theme-var($--ch-theme-highlight);
      }

      &.active {
        color: theme-var($--ch-theme-highlight);
      }

      @for $i from 1 through 6 {
        &.tab-#{$i}.active {
          & ~ .highlighter {
            transform: translateY(#{($i * $tab-height) - $tab-height});
          }
        }
      }
    }

    .highlighter {
      display: block;
      background: theme-var($--ch-theme-highlight);
      width: 3px;
      height: $tab-height;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(0);
      transition: transform 0.2s ease;
      transition-delay: 0.1s;
      z-index: 10;
    }
    button:first-of-type {
      border-top-left-radius: 2px;
    }
    button:last-of-type {
      border-top-right-radius: 2px;
    }
  }
  .content {
    position: relative;
    padding: 0 $spacing;
    font-family: $font-main;
    letter-spacing: 0.5px;
    &__section {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0s ease;

      &.visible {
        position: relative;
        opacity: 1;
        width: 100%;
        height: auto;
        transition: opacity 0.5s ease;
        z-index: 2;
      }
      p {
        margin: 0;
      }
      ul {
        padding: 1rem;
        margin: 0;
      }
      li {
        padding-left: 30px;
      }
    }
  }
}
</style>
