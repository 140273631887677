<template>
  <div class="vertical-tabs-cnt">
    <b-card no-body>
      <b-tabs v-model="tabIndex" pills card vertical nav-wrapper-class="w-20">
        <b-tab v-for="(tab, index) in tabs" :key="'tab' + index">
          <template #title>
            <div class="bounce-button-cnt">
              <a
                href=""
                class="bounce-button"
                :class="{ active: index === tabIndex }"
                >{{ tab.title }}</a
              >
            </div>
          </template>
          <b-card-text>
            <p>{{ tab.role }}</p>
            <p>{{ tab.time }}</p>
            <ul v-html="tab.desc"></ul>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "VerticalTab",
  props: { tabs: Array },
  data() {
    return {
      tabIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.vertical-tabs-cnt {
  max-width: 800px;
  margin: 0 auto;
  .card {
    .tabs {
      :deep(.card-header) {
        min-height: 344px;
        // height: 100%;
        display: flex;
        .nav {
          //   min-height: 344px;
          //   justify-content: center;
          &-item {
            // flex-basis: 100%;
            .nav-link {
              &.active {
                background-color: transparent;
                // .bounce-button-cnt {
                //   .bounce-button {
                //     &::before {
                //       //   background: #e45447;
                //       transform: scaleX(1);
                //       z-index: -1;
                //     }
                //   }
                // }
              }
            }
          }
        }
      }
      :deep(.tab-content) {
        max-width: 640px;
        min-height: 344px;
      }
    }
  }
}
</style>
