<template>
  <div class="sideBand-cnt">
    <div class="sideBand sideBand-left">
      <!-- <div class="sideBand-div" v-for="n in 15" :key="'leftBandDiv' + n">
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
      </div> -->
      <div class="sideBand-div">
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
      </div>
    </div>
    <div class="sideBand sideBand-right">
      <!-- <div class="sideBand-div" v-for="n in 15" :key="'rightBandDiv' + n">
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
      </div> -->
      <div class="sideBand-div">
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
        ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}   ramanheera00@gmail.com {{ "\xA0" }}  {{ "\xA0" }}  
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, Power0 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { onMounted } from "vue";
export default {
  name: "SideBand",
  setup() {
    function sideBandScroll() {
      gsap.registerPlugin(ScrollTrigger);

      gsap.set(".sideBand-div", { yPercent: -50 });

      gsap.config({
        overwrite: "auto",
      });

      var time = 10;
      var velo;
      var tooFast = 7;
      var limit = 0.1;
      var fastLimit = 0.5;

      var action = gsap.to(".sideBand-div", {
        y: "+=3000",
        modifiers: {
          y: gsap.utils.unitize((y) => parseFloat(y) % 3000), //force x value to be between 0 and 500 using modulus
        },
        duration: time,
        repeat: -1,
        ease: Power0.easeNone,
        paused: true,
      });

      var ScrolloMeter = ScrollTrigger.create({
        trigger: "#onstart",
        pin: true,
        start: "top top",
        endTrigger: ".contact_us",
        end: "bottom bottom",
        scrub: 1,
        onEnter: (isActive) => action.play(0),
        onUpdate: (self) => {
          velo = self.getVelocity();
          let speed = velo * 0.0003;
          //   if (speed > 0.7 || speed < -0.7) {
          //     speed = velo * 0.0001;
          //   }
          //   console.log(speed);

          if (speed < -limit) {
            if (speed < -fastLimit) {
              gsap.to(action, { timeScale: -fastLimit, duration: 1 });
            } else {
              gsap.to(action, { timeScale: speed, duration: 1 });
            }
          } else if (speed > limit) {
            if (speed > fastLimit) {
              gsap.to(action, { timeScale: fastLimit, duration: 1 });
            } else {
              gsap.to(action, { timeScale: speed, duration: 1 });
            }
          } else if (speed == 0) {
            // ?????
            gsap.to(action, { timeScale: 0, duration: 1 });
          }
          //   gsap.set(action, { timeScale: speed });

          //   if (speed > tooFast || speed < -tooFast) {
          //     gsap.set(".green", { backgroundColor: "red" });
          //   } else {
          //     gsap.set(".green", { backgroundColor: "green" });
          //   }
        },
      });
    }
    onMounted(() => {
      sideBandScroll();
    });
  },
};
</script>

<style lang="scss" scoped>
.sideBand-cnt {
  .sideBand {
    position: fixed;
    writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    background: theme-var($--ch-theme-text-dark);
    color: #eae2d9;
    height: 100vh;
    font-size: 1.4583333333333333vw;
    width: 3.3333333333333335vw;
    overflow-y: hidden;
    top: 0;
    &-ani {
      animation-duration: 5s;
      animation-timing-function: cubic-bezier(0, 0, 0, 0);
      animation-iteration-count: infinite;
    }
    div {
      display: block;
      padding-bottom: 1.0416666666666665vw;
      white-space: nowrap;
      will-change: transform;

      // margin-right: 0.9722222222222222vw;
    }
    &-left {
      left: 0;
      transform: scale(-1, -1);
      div {
        animation-name: sideBandAni-left;
      }
    }
    &-right {
      right: 0;
      div {
        animation-name: sideBandAni-right;
      }
    }
    @keyframes sideBandAni-left {
      0% {
        transform: translate(0, 0);
      }

      100% {
        transform: translate(0, -100%);
      }
    }
    @keyframes sideBandAni-right {
      0% {
        transform: translate(0, -100%);
      }

      100% {
        transform: translate(0, 0);
      }
    }
  }
}
</style>
