<template>
  <div :class="{ loader: true, 'slide-up': !isLoading && !errorMsg }">
    <div v-if="errorMsg">
      <svg class="icon" viewBox="0 0 16 16">
        <path
          d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.854 7.146 8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 1 1 .708-.708z"
        />
      </svg>
      <h4 class="mt-3 error">{{ errorMsg }}</h4>
    </div>
    <div class="load-logo" v-else>
      <LogoLoading />
      <!-- <div
        class="spinner-border m-3 text-light"
        style="width: 1.4em; height: 1.4em"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div> -->
      <!-- <p>Loading...</p> -->
      <!-- <h4 class="mt-3">Please wait while we load our goodies.</h4> -->
    </div>
  </div>
</template>

<script>
import LogoLoading from "@/components/LogoLoading";

export default {
  name: "LoadingScreen",
  props: ["isLoading", "errorMsg"],
  components: {
    LogoLoading,
  },
};
</script>

<style lang="scss" scoped>
.loader {
  background-color: theme-var($--ch-theme-text-dark);
  top: -10%;
  height: calc(100vh + 10%);
  color: theme-var($--ch-theme-text-light);
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 9999999999;
  h4 {
    font-size: 0.8rem;
    // color: theme-var($--ch-theme-on-primary);
    &.error {
      // color: theme-var($--ch-theme-on-primary);
    }
  }
  p {
    font-size: 0.5rem;
    // color: theme-var($--ch-theme-on-primary);
    text-transform: uppercase;
    margin-left: 8px;
  }
  .load-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 200px;
    min-width: 200px;
  }
}
.logo {
  width: 130px;
}
.icon {
  fill: theme-var($--ch-theme-on-primary);
  width: 55px;
}
.slide-up {
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
  animation: animLoadedLoader 1s cubic-bezier(1, -0.17, 0.3, 1.07) forwards;
}

@keyframes animLoadedLoader {
  to {
    transform: translate3d(0, -100%, 0);
  }
}
</style>
