<template>
  <div id="benefit_top" class="benefit_top">
    <div class="benefit_top_content container">
      <SectionHeader topText="your Top" bottomText="benefits" />
      <div class="benefits">
        <div class="benefit_box" v-for="benefit in benefits" :key="benefit.id">
          <Box :benefit="benefit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import Box from "./Box";

export default {
  name: "BenefitTop",
  components: { SectionHeader, Box },
  data() {
    return {
      benefits: [
        {
          id: 0,
          text: "Channel1 GOLD Concession Pricing",
          icon: "concession.png",
        },
        {
          id: 1,
          text: "Member-Only Fast Lanes ",
          icon: "fast.png",
        },
        {
          id: 2,
          text: "Presale access for events at FedExField",
          icon: "tickets.png",
        },
        {
          id: 3,
          text: "Dedicated Membership Services Representative",
          icon: "Rep.png",
        },
        {
          id: 4,
          text: "Discount at the Channel1 football team store",
          icon: "discount.png",
        },
        {
          id: 5,
          text: "Exclusive, Member-Only events throughout the year",
          icon: "events.png",
        },
        {
          id: 6,
          text: "Free access to NFL Games Pass and RedZone",
          icon: "access.png",
        },
        {
          id: 7,
          text: "Monthly Newsletter",
          icon: "newsletter.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.benefit_top {
  // background-image: url("~images/gold-member/bgImg.jpeg");
  // background-size: cover;
  background-color: theme-var($--ch-theme-background-primary);
  display: flex;
  position: relative;
  &_content {
    align-items: center;
    margin-block: 15vh;
    @include media-breakpoint-down(sm) {
      margin-block: 5vh;
    }
    z-index: 1;
    @include media-breakpoint-up(xl) {
      max-width: 890px;
    }
  }
  .benefits {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: auto;
    gap: 15px;
    .benefit_box {
      flex: 0 0 205px;
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
      }
    }
  }
}
</style>