<template>
  <div id="projects">
    <div class="projects">
      <div class="projects_content" data-aos="fade-up">
        <div id="projectCarousel" class="carousel slide" data-bs-ride="false">
          <!-- <ol class="carousel-indicators">
            <li
              v-for="(project, index) in projectsList"
              :key="'indicator-' + index"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
              :class="
                index === 0 ? 'carousel-indicator active' : 'carousel-indicator'
              "
            ></li>
          </ol> -->
          <div class="carousel-inner">
            <div
              v-for="(project, index) in projectsList"
              :key="project.title"
              :class="index === 0 ? 'carousel-item active' : 'carousel-item'"
            >
              <!-- <img
                class="d-block w-100"
                :src="require(`@/${project.pic}`)"
                alt="Project Slide"
              /> -->
              <div class="project-box">
                <img
                  :src="require(`@/${project.logo}`)"
                  alt=""
                  class="project-logo"
                />
                <div class="project-text">
                  <!-- <h2 class="project-title">
                    {{ project.title }}
                  </h2> -->

                  <!-- <p class="project-desc">
                    {{ project.desc }}
                  </p> -->
                  <a
                    class="modalButton"
                    :href="project.link"
                    target="_blank"
                    rel="noopener noreferrer"
                    :aria-label="`Preview ${project.title} Site`"
                  >
                    <span class="buttonText">Preview</span>
                    <font-awesome-icon
                      class="modal-fa"
                      icon="fa-solid fa-maximize"
                      size="2x"
                    />
                  </a>
                </div>

                <img
                  class="project-image"
                  :alt="project.title"
                  :src="require(`@/${project.pic}`)"
                />
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#projectCarousel"
            role="button"
            data-bs-slide="prev"
            aria-label="View Previous Project"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#projectCarousel"
            role="button"
            data-bs-slide="next"
            aria-label="View Next Project"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projects } from "@/assets/data/projects.js";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProjectsMobile",
  setup() {
    const projectsList = projects;
    const activeProj = ref(0);
    function openModal(link) {
      this.$store.dispatch(`modal/setSiteURL`, link);
    }
    return { projectsList, activeProj, openModal };
  },
};
</script>

<style lang="scss" scoped>
.projects {
  &_content {
    align-items: center;
    // margin-block: 30vh;
    @include media-breakpoint-down(sm) {
      margin-top: 5vh;
    }
    z-index: 1;
    @include media-breakpoint-up(xl) {
      //   max-width: 890px;
    }
    .carousel {
      &-inner {
        .carousel-item {
          .project-box {
            margin: 2em;
            z-index: 1;
            .project-logo {
              max-width: 100px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              filter: drop-shadow(8px 7px 23px #000) !important;
              z-index: 4;
              @include media-breakpoint-down(xs) {
                max-width: 70px;
              }
            }
            .project-text {
              width: calc(100% - 4em);
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: left;
              z-index: 3;
              position: absolute;
              bottom: 0;
              left: 0;
              margin: 2em;
              background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.85),
                transparent
              );
              color: #fff;
              padding: 1.5em 1em 1em;
              border-radius: 0 0 8px 8px;
              .project-desc {
                color: #fff;
                z-index: 3;
                // background: linear-gradient(
                //   rgba(10, 0, 50, 0.7),
                //   rgba(10, 0, 50, 0.7)
                // );
              }
              .project-title {
                text-align: left;
              }
              .modalButton {
                position: relative;
                padding: 5px 0;
                width: 200px;
                height: 50px;
                border: none;
                background: rgba(255, 255, 255, 0.4);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                transition: all 0.2s linear;
                text-align: center;
                text-decoration: none;
                // will-change: background;
                cursor: pointer;
                .buttonText {
                  position: relative;
                  bottom: 0px;
                  opacity: 1;
                  transition: all 0.15s ease-out, color 0.2s linear,
                    opacity 0.15s ease-out;
                  color: theme-var($--ch-theme-text);
                  white-space: nowrap;
                  will-change: bottom, color, opacity;
                  text-transform: uppercase;
                  font-family: $font-header;
                  font-size: 25px;
                  line-height: 50px;
                }
                .modal-fa {
                  color: theme-var($--ch-theme-text);
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%) scale(0);
                  transition: all 0.2s cubic-bezier(0.55, 0.06, 0.75, 0.54),
                    color 0.2s linear;
                  will-change: transform;
                }

                &:hover {
                  .buttonText {
                    bottom: 30px;
                    opacity: 0;
                  }
                  .modal-fa {
                    transform: translate(-50%, -50%) scale(1);
                  }
                }
              }
            }
            .project-image {
              opacity: 1;
              display: block;
              width: 100%;
              // height: 100%;
              transition: 0.5s ease;
              backface-visibility: hidden;
              border-radius: 8px;
              object-fit: cover;
              object-position: center top;
              box-shadow: 5px 5px 15px rgba(#000, 0.5);
              // filter: grayscale(50%);
            }
          }
        }
      }
      .carousel-control-next,
      .carousel-control-prev {
        opacity: 0.8;
        margin: 10px;
        &:hover {
          opacity: 1;
        }
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        height: 40px;
        width: 40px;
        // outline: black;
        background-size: 100%, 100%;
        background: #ece4b7;
        border-radius: 50%;
        // padding: 10px;
        // border: 1px solid black;
        // background-image: #fff;
      }
      .carousel-control-prev-icon {
        // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff8e31' viewBox='0 0 24 24'%3E%3Cpath d='M17,11H9.414l1.293-1.293A1,1,0,0,0,9.293,8.293l-3,3a1,1,0,0,0,0,1.414l3,3a1,1,0,0,0,1.414-1.414L9.414,13H17a1,1,0,0,0,0-2Z'/%3E%3C/svg%3E");
      }

      .carousel-control-next-icon {
        // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff8e31' viewBox='0 0 24 24'%3E%3Cpath d='M17,11H9.414l1.293-1.293A1,1,0,0,0,9.293,8.293l-3,3a1,1,0,0,0,0,1.414l3,3a1,1,0,0,0,1.414-1.414L9.414,13H17a1,1,0,0,0,0-2Z'/%3E%3C/svg%3E");
        transform: rotate(180deg);
      }
      // .carousel-control-next-icon:after {
      //   content: ">";
      //   font-size: 22px;
      //   color: red;
      // }
      // .carousel-control-prev-icon:after {
      //   content: "<";
      //   font-size: 22px;
      //   color: red;
      // }
    }
  }
}
</style>
