<template>
  <div id="important_dates">
    <div class="important_dates">
      <div class="important_dates_content container">
        <SectionHeader topText="Important" bottomText="dates" :white="true" />
        <div class="boxes">
          <div>
            <h3>Thursday, December 16<sup>th</sup></h3>
            <p>
              early renewal deadline to recieve<br />limited-edition team duffle
              bag
            </p>
          </div>
          <div>
            <h3>Friday, January 21<sup>st</sup></h3>
            <p>final 2022 renewal deadline</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import { onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  components: { SectionHeader },
  setup() {
    // // State
    function pinScroll() {
      gsap.registerPlugin(ScrollTrigger);
    }
    onMounted(() => {
      pinScroll();
    });
  },
};
</script>

<style lang="scss" scoped>
.important_dates {
  // background-image: url("~images/dates/bg.jpeg");
  background-color: theme-var($--ch-theme-primary);

  background-size: cover;
  display: flex;
  position: relative;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &_content {
    align-items: center;
    margin-block: 25vh;
    @include media-breakpoint-down(sm) {
      margin-block: 5vh;
    }
    z-index: 1;
    @include media-breakpoint-up(xl) {
      max-width: 1040px;
    }
    .boxes {
      text-transform: uppercase;
      display: flex;
      gap: 20px;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
      div {
        width: 50%;
        background: white;
        padding: 30px;
        @include media-breakpoint-down(sm) {
          padding: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      h3 {
        sup {
          color: theme-var($--ch-theme-on-primary);
        }
        color: theme-var($--ch-theme-on-primary);
        font-size: 30px;
        @include media-breakpoint-down(lg) {
          font-size: 25px;
        }
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: -0.03em;
      }
      p {
        color: theme-var($--ch-theme-on-primary);
        font-family: $font-main;
        font-size: 16px;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0px;
      }
    }
    .accordion_wrapper {
      margin-top: 50px;
    }
  }
}
</style>
