<template>
  <div id="contact_us">
    <div class="contact_us borderSeperator">
      <div class="contact_us_content container">
        <div class="copy" data-aos="fade-up">
          <h3 class="contact-title">Let's keep in touch</h3>
          <p>
            My inbox is always open, I'll try my best to get back to you ASAP
          </p>
          <div class="btn_wrap">
            <span>Contact Me</span>
            <div class="btn_wrap_cnt">
              <a
                href="https://www.linkedin.com/in/rheera/"
                target="_blank"
                rel="noopener noreferrer"
                class="contact-icon"
                aria-label="Visit LinkedIn Profile"
              >
                <font-awesome-icon
                  class="contact-icon-fa"
                  icon="fa-brands fa-linkedin-in"
                />
              </a>
              <a
                href="mailto: ramanheera00@gmail.com"
                class="contact-icon"
                aria-label="Send me an Email"
              >
                <font-awesome-icon
                  class="contact-icon-fa"
                  icon="fa-solid fa-envelope"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="to_top hidden" @click="toTop()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <defs>
              <clipPath>
                <path
                  fill="#00f"
                  fill-opacity=".514"
                  d="m-7 1024.36h34v34h-34z"
                />
              </clipPath>
              <clipPath>
                <path
                  fill="#aade87"
                  fill-opacity=".472"
                  d="m-6 1028.36h32v32h-32z"
                />
              </clipPath>
            </defs>
            <path
              d="m345.44 248.29l-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373"
              transform="matrix(-.00013-.03541.03541-.00013 3.02 19.02)"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    // State
    const state = reactive({
      repInfo: computed(() => store.getters["purl/getAccountRep"]),
    });
    function toTop() {
      // VueScrollTo.scrollTo("#intro");
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    function showToTop() {
      const showOnPx = 100;
      const backToTopButton = document.querySelector(".to_top");

      const scrollContainer = () => {
        return document.documentElement || document.body;
      };

      document.addEventListener("scroll", () => {
        if (scrollContainer().scrollTop > showOnPx) {
          backToTopButton.classList.remove("hidden");
        } else {
          backToTopButton.classList.add("hidden");
        }
      });
    }
    onMounted(() => {
      showToTop();
    });
    return { ...toRefs(state), toTop };
  },
};
</script>

<style lang="scss" scoped>
.contact_us {
  // background-image: url("~images/contact/bg.jpeg");
  // background-position: right;
  // background-size: cover;
  display: flex;
  position: relative;
  // background-color: theme-var($--ch-theme-background-secondary);
  margin: 0 auto;
  margin-block: 15vh;
  // max-height: 700px;
  // padding: 120px 0;
  // border-top: 2px solid #000;

  @include media-breakpoint-down(sm) {
    margin-block: 5vh;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1040px;
  }
  &_content {
    align-items: center;
    margin-block: 20vh;
    @include media-breakpoint-down(sm) {
      margin-top: 5vh;
    }
    z-index: 1;
    @include media-breakpoint-up(xl) {
      max-width: 890px;
    }
    .copy {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      font-family: $font-main;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      p {
        font-size: 18px;
        letter-spacing: 0.5px;
      }
      .contact-title {
        font-family: $font-header;
        font-size: 4rem;
        color: theme-var($--ch-theme-text-heading);
      }
      .btn_wrap {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        width: 240px;
        height: 72px;
        background: #eae2d9;
        border: 2px solid theme-var($--ch-theme-text-dark);

        // border-radius: 80px;
        padding: 0 18px;
        will-change: transform;
        box-shadow: -1px 1px theme-var($--ch-theme-text-dark),
          -2px 2px theme-var($--ch-theme-text-dark),
          -3px 3px theme-var($--ch-theme-text-dark),
          -4px 4px theme-var($--ch-theme-text-dark),
          -5px 5px theme-var($--ch-theme-text-dark),
          -6px 6px theme-var($--ch-theme-text-dark),
          -7px 7px theme-var($--ch-theme-text-dark),
          -8px 8px theme-var($--ch-theme-text-dark);
        transform: translateX(8px) translateY(-8px);
        transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
        -webkit-transition: all 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);

        // -webkit-transition: all 0.2s ease-in-out;
        // transition: all 0.2s ease-in-out;
        &:hover {
          // -webkit-transform: scale(1.1);
          // transform: scale(1.1);
          box-shadow: -8px 8px theme-var($--ch-theme-text-dark),
            -9px 9px theme-var($--ch-theme-text-dark),
            -10px 10px theme-var($--ch-theme-text-dark),
            -11px 11px theme-var($--ch-theme-text-dark),
            -12px 12px theme-var($--ch-theme-text-dark),
            -13px 13px theme-var($--ch-theme-text-dark),
            -14px 14px theme-var($--ch-theme-text-dark),
            -15px 15px theme-var($--ch-theme-text-dark);
          transform: translateX(15px) translateY(-15px);
          span {
            -webkit-transition-delay: 0.25s;
            transition-delay: 0.25s;
            -webkit-transform: translateX(-280px);
            transform: translateX(-280px);
          }
          .contact-icon {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
            // color: theme-var($--ch-theme-on-secondary);
            &:hover {
              // transition-delay: 0s;
              // transition-property: color;
              // -webkit-transform: scale(1.1);
              // transform: scale(1.1);
              color: theme-var($--ch-theme-highlight);
            }
          }
        }
        span {
          position: absolute;
          z-index: 99;
          width: 240px;
          height: 72px;
          // border-radius: 80px;
          text-align: center;
          line-height: 70px;
          font-family: $font-main;
          color: theme-var($--ch-theme-text-dark);
          font-size: 18px;
          letter-spacing: 0.5px;
          // color: #000;
          text-decoration: none;
          border: 2px solid theme-var($--ch-theme-text-dark);
          background-color: #eae2d9;
          padding: 0 18px;
          -webkit-transition: all 1.2s ease;
          transition: all 1.2s ease;
        }
        &_cnt {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: distribute;
          justify-content: space-around;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 240px;
          height: 64px;
          // border-radius: 80px;
          .contact-icon:nth-of-type(1) {
            -webkit-transition-delay: 0.7s;
            transition-delay: 0.7s;
            // transition-property: transform;
          }
          .contact-icon:nth-of-type(2) {
            -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;
            // transition-property: transform;
          }
          .contact-icon {
            opacity: 0;
            font-size: 28px;
            will-change: transform;
            -webkit-transform: scale(0.1);
            transform: scale(0.1);
            -webkit-transition: transform 0.3s ease, opacity 0.3s ease;
            transition: transform 0.3s ease, opacity 0.3s ease;
            color: theme-var($--ch-theme-text-dark);
          }
        }
      }
    }

    .to_top {
      position: fixed;
      width: 50px;
      height: 50px;
      right: 4vw;
      border-radius: 50%;
      bottom: 100px;
      background: theme-var($--ch-theme-text);
      text-transform: uppercase;
      color: theme-var($--ch-theme-background-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-align: center;
      opacity: 1;
      visibility: visible;
      transition: all 0.5s;
      svg {
        fill: theme-var($--ch-theme-background-primary);
        width: 30px;
      }
      &.hidden {
        visibility: hidden;
        opacity: 0;
        bottom: 25px;
      }
      @include media-breakpoint-up(xs) {
        right: 4vw;
        bottom: 50px;
      }
    }
  }
}
</style>
